import { useEffect, useState } from "react";
import {
	StyleSheet,
	Text,
	View,
	FlatList,
	TextInput,
	Platform,
} from "react-native";
import { useContentContext } from "../providers/ContentProvider";
import { CreatorType } from "../types/creator/Creator";
import ProfileSearchComponent from "../components/Search/ProfileSearchComponent";
import { SafeAreaView } from "react-native-safe-area-context";
import { G, Path, Svg, SvgXml } from "react-native-svg";

import { patchFlatListProps } from "react-native-web-refresh-control";
import { similarity } from "../utils/StringOperation";
if (Platform.OS === "web") patchFlatListProps({ tintColor: "#DB7BC6" });
const maxCreatorsToShow = 5;

export default function Search({ navigation }: { navigation: any }) {
	const [search, setSearch] = useState("");
	const { creators } = useContentContext();
	const [searchResults, setSearchResults] = useState<CreatorType[]>([]);
	useEffect(() => {
		if (search.length === 0) {
			setSearchResults([]);
		} else {
			const creatorQuery: { creator: CreatorType; likely: number }[] = [];
			for (let i = 0; i < creators.length; i++) {
				const likely = similarity(
					search.toLocaleLowerCase(),
					creators[i].name.toLocaleLowerCase()
				);
				creatorQuery.push({ creator: creators[i], likely });
			}
			creatorQuery.sort((a, b) => b.likely - a.likely);
			const creato: CreatorType[] = [];
			for (let i = 0; i < creatorQuery.length; i++) {
				if (creatorQuery[i].likely > 0) creato.push(creatorQuery[i].creator);
			}

			// const filteredCreator = creators.filter((creator) => creator.name.toLowerCase().includes(search.toLowerCase()));
			setSearchResults(creato);
		}
	}, [search]);
	const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));
	const [reload, setReload] = useState(false);
	async function handleReload() {
		setReload(true);
		await wait(500);
		setReload(false);
	}
	return (
		<>
			<SafeAreaView style={styles.search_container}>
				<View style={styles.search_bar}>
					<TextInput
						style={styles.input}
						onChangeText={setSearch}
						value={search}
					/>
					<Svg
						fill="white"
						width="25"
						height="25"
						id="lni_lni-diamond-alt"
						x="0px"
						y="0px"
						viewBox="0 0 15.006028 21.97554"
					>
						<G transform="translate(-61.70614,-104.87948)">
							<Path d="m 70.538566,104.87958 c -1.442659,-0.007 -2.902628,0.33947 -4.245736,1.07177 -3.907223,2.1303 -5.610579,6.86063 -3.957897,10.99261 1.5733,3.93351 5.722841,6.1634 9.826274,5.37487 l 2.358511,6.03322 a 0.75,0.75 0 0 0 0.971001,0.4253 0.75,0.75 0 0 0 0.425814,-0.97255 l -2.582272,-6.60322 a 0.75,0.75 0 0 0 -0.468705,-0.43925 0.75,0.75 0 0 0 -0.472322,-0.0346 c -3.576426,0.9583 -7.289558,-0.90353 -8.664588,-4.34134 -1.375028,-3.4378 0.03065,-7.34434 3.281453,-9.11676 3.250803,-1.77241 7.299526,-0.83939 9.444385,2.17868 2.144859,3.01808 1.69488,7.14744 -1.047999,9.63455 a 0.75,0.75 0 0 0 -0.05219,1.05938 0.75,0.75 0 0 0 1.059367,0.0517 c 2.472548,-2.24201 3.395691,-5.61328 2.615344,-8.6894 -0.260117,-1.02536 -0.709435,-2.01853 -1.353925,-2.92541 -1.691783,-2.38054 -4.382344,-3.6855 -7.136514,-3.69951 z" />
						</G>
					</Svg>
				</View>

				{searchResults.length > 0 && (
					<FlatList
						style={{
							marginBottom: 0,
							alignSelf: "stretch",
							padding: 0,
							overflow: "scroll",
						}}
						contentContainerStyle={{ marginBottom: 90 }}
						data={searchResults.slice(0, maxCreatorsToShow)}
						onRefresh={handleReload}
						refreshing={reload}
						renderItem={({ item }) => (
							<ProfileSearchComponent creator={item} navigation={navigation} />
						)}
						keyExtractor={(item) => item.address}
					/>
				)}
			</SafeAreaView>
		</>
	);
}
const styles = StyleSheet.create({
	search_container: {
		flex: 1,
		backgroundColor: "#151721",
		padding: 16,
		display: "flex",
		alignItems: "center",
		height: "100%",
	},
	input: {
		color: "#DB7BC6",
		backgroundColor: "transparent",
		flex: 1,
		alignSelf: "flex-start",
	},
	search_bar: {
		backgroundColor: "transparent",
		borderRadius: 16,
		borderColor: "#aaa",
		borderWidth: 3,
		width: "100%",
		padding: 8,
		paddingRight: 16,
		marginBottom: 32,
		alignSelf: "flex-start",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
});
