import { StyleSheet, Text, View, Pressable } from "react-native";
import { useWalletContext } from "../../providers/WalletProvider";
import * as Clipboard from "expo-clipboard";
import { Feather } from "@expo/vector-icons";
import { useSnackbar } from "../../providers/SnackBarProvider";
import { useTranslation } from "react-i18next";

export default function WalletInformation() {
	const { walletInfo } = useWalletContext();
	const { openSnackbar } = useSnackbar();
	const { t } = useTranslation();
	async function copyToClipboard(wallet_info: string | undefined) {
		if (wallet_info == null) return;
		try {
			await Clipboard.setStringAsync(wallet_info);
			openSnackbar("Success", t("messanges.saved_in_clipboard"), "success");
		} catch (e) {
			openSnackbar("Error", t("errors.saved_in_clipboard"), "error");
			console.log(e);
		}
	}
	return (
		<>
			<View style={styles.wallet_information_container}>
				<View style={styles.wallet_field_container}>
					<Pressable
						onPress={async () => {
							await copyToClipboard(walletInfo?.address);
						}}
						style={{
							flexDirection: "row",
							gap: 8,
							alignItems: "center",
							borderRadius: 32,
							backgroundColor: "rgba(219, 123, 198, 0.4)",
							paddingHorizontal: 16,
							paddingVertical: 8,
							alignSelf: "flex-start",
						}}
					>
						<Feather name="copy" size={20} color="white" />
						<Text
							style={[
								styles.wallet_information_text,
								{ fontSize: 16, fontFamily: "Gilroy-Bold" },
							]}
						>
							{t("common.address")}
						</Text>
					</Pressable>
					<Text style={styles.wallet_information_text}>
						{walletInfo?.address}
					</Text>
				</View>
				<View style={styles.wallet_field_container}>
					<Pressable
						onPress={async () => {
							await copyToClipboard(walletInfo?.mnemonic);
						}}
						style={{
							flexDirection: "row",
							gap: 8,
							alignItems: "center",
							borderRadius: 32,
							backgroundColor: "rgba(219, 123, 198, 0.4)",
							paddingHorizontal: 16,
							paddingVertical: 8,
							alignSelf: "flex-start",
						}}
					>
						<Feather name="copy" size={20} color="white" />
						<Text
							style={[
								styles.wallet_information_text,
								{ fontSize: 16, fontFamily: "Gilroy-Bold" },
							]}
						>
							Mnemonic
						</Text>
					</Pressable>
					<Text style={styles.wallet_information_text}>
						{walletInfo?.mnemonic}
					</Text>
				</View>
				<View style={styles.wallet_field_container}>
					<Pressable
						onPress={async () => {
							await copyToClipboard(walletInfo?.privateKey);
						}}
						style={{
							flexDirection: "row",
							gap: 8,
							alignItems: "center",
							borderRadius: 32,
							backgroundColor: "rgba(219, 123, 198, 0.4)",
							paddingHorizontal: 16,
							paddingVertical: 8,
							alignSelf: "flex-start",
						}}
					>
						<Feather name="copy" size={20} color="white" />
						<Text
							style={[
								styles.wallet_information_text,
								{ fontSize: 16, fontFamily: "Gilroy-Bold" },
							]}
						>
							{t("common.private_key")}
						</Text>
					</Pressable>
					<Text style={styles.wallet_information_text}>
						{walletInfo?.privateKey}
					</Text>
				</View>
			</View>
		</>
	);
}
const styles = StyleSheet.create({
	wallet_information_container: {
		display: "flex",
		flexDirection: "column",
		rowGap: 16,
	},
	wallet_field_container: {
		backgroundColor: "#232839",
		padding: 16,
		borderRadius: 16,
		rowGap: 8,
	},
	wallet_information_text: {
		color: "white",
	},
});
