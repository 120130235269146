import { createMaterialTopTabNavigator, MaterialTopTabBar, MaterialTopTabBarProps } from "@react-navigation/material-top-tabs";
// const Tab = createMaterialTopTabNavigator();
import { createBottomTabNavigator, BottomTabBar } from "@react-navigation/bottom-tabs";

import { useEffect } from "react";
import Feed from "./pages/Feed";
import Profile from "./pages/Profile";
import Search from "./pages/Search";
import MyPurchase from "./pages/MyPurchase";
import Categories from "./pages/Categories";
import { normalize } from "./utils/responsiveText/responsiveText";
import premiumIcon from "./assets/svgs/premium.svg";
import searchIcon from "./assets/svgs/search.svg";
import homeIcon from "./assets/svgs/home.svg";
import categoriesIcon from "./assets/svgs/categories.svg";
import accountIcon from "./assets/svgs/account.svg";
import { SvgXml } from "react-native-svg";
import { BlurView } from "expo-blur";
import { LinearGradient } from "expo-linear-gradient";
import { StyleSheet, Platform } from "react-native";
import { SafeAreaProvider, useSafeAreaInsets } from "react-native-safe-area-context";
import Svg, { Circle, Path, Rect, G } from "react-native-svg";
const styles = StyleSheet.create({
	tabBar: {
		borderRadius: 24,
		bottom: 16,
		// shadowColor: "transparent",
		width: "80%",
		left: Platform.OS === "web" ? "auto" : undefined,
		right: "auto",
		maxWidth: 400,
		alignSelf: "center",
		position: "absolute",
		height: 70,
		justifyContent: "center",

		// borderColor: "transparent",
		backgroundColor: "#1e212d",
		borderWidth: 0,
		borderTopWidth: 0,
		borderTopColor: "transparent",
		padding: 8,
	},
	gradientTabBar: {
		// alignSelf: "flex-end",
		position: "absolute",
		bottom: 0,
		height: 150,
		right: 0,
		width: "100%",
		flex: 1,
	},
});

const topBarProps = {
	tabBarStyle: styles.tabBar,
	tabBarPressOpacity: 0.5,
	tabBarActiveTintColor: "#DB7BC6",
	tabBarInactiveTintColor: "white",
	tabBarLabelStyle: {
		fontSize: normalize(8),
	},
	// tabStyle: {
	// 		padding: 16,
	// 	},
	tabBarContentContainerStyle: {
		borderColor: "transparent",
		shadowColor: "transparent",
	},
	tabBarIndicatorStyle: {
		backgroundColor: "blue",
		height: 0,
	},
	lazy: true,
};
const bottomBarProps = {
	headerShown: false,
	tabBarStyle: styles.tabBar,
	headerPressOpacity: 0.5,
	// tabBarPressOpacity: 0.5,

	tabBarActiveTintColor: "#DB7BC6",
	tabBarInactiveTintColor: "white",
	tabBarLabelStyle: {
		fontSize: normalize(8),
	},
	// tabStyle: {
	// 		padding: 16,
	// 	},
	headerBackgroundContainerStyle: {
		borderColor: "transparent",
		shadowColor: "transparent",
	},

	// tabBarIndicatorStyle: {
	// 	backgroundColor: "blue",
	// 	height: 0,
	// },
	lazy: true,

	// swipeEnabled: false,
	// animationEnabled: false,
};
const Tab = createBottomTabNavigator();
const TopTab = createMaterialTopTabNavigator();

export default function HomeNavigator({ navigation }: { navigation: any }) {
	useEffect(() => {
		navigation.addListener("beforeRemove", (e: any) => {
			if (e.data.action.payload.name !== "Authentication") e.preventDefault();
		});
	}, [navigation]);
	const inset = useSafeAreaInsets();
	return (
		<>
			{Platform.OS === "web" ? (
				<Tab.Navigator
					// tabBarPosition="bottom"
					// tabBar={(props) => <CustomTabBar {...props} />}
					tabBar={(props) => {
						return (
							<LinearGradient
								colors={["#151721", "transparent"]}
								start={{ x: 0, y: 1 }}
								end={{ x: 0, y: 0 }}
								style={styles.gradientTabBar}
								pointerEvents="none"
							>
								<BottomTabBar
									{...props}
									//  style={{ backgroundColor: "transparent" }}
								/>
							</LinearGradient>
						);
					}}
					screenOptions={{ ...bottomBarProps, title: "", headerStyle: { borderBottomColor: "transparent", borderWidth: 0 } }}
				>
					<Tab.Screen
						name="Feed"
						component={Feed}
						options={{
							tabBarIcon: ({ color }) => (
								<Svg fill={color} width="25" height="25" id="lni_lni-diamond-alt" x="0px" y="0px" viewBox="0 0 25.056833 25.000784">
									<G transform="translate(-15.009693,-104.65547)">
										<Path d="m 27.15625,104.77734 c 0,0 -2.984791,1.95285 -5.976563,4.38477 -1.495885,1.21596 -2.994617,2.55152 -4.146484,3.85352 -1.151866,1.30199 -2.032793,2.53349 -2.023437,3.86328 l 0.05664,7.96679 c 0.01863,2.64886 2.163136,4.81055 4.820313,4.81055 h 7.523437 7.835938 c 2.657176,0 4.839032,-2.15876 4.820312,-4.82031 l -0.05664,-7.9668 c -0.009,-1.28515 -0.885066,-2.46015 -2.025391,-3.74609 -1.140325,-1.28595 -2.622688,-2.62841 -4.099609,-3.85938 -2.953842,-2.46193 -5.894532,-4.47656 -5.894532,-4.47656 a 0.75012501,0.75012501 0 0 0 -0.833984,-0.01 z m 0.402344,1.5625 c 0.372813,0.25788 2.686046,1.84316 5.365234,4.07618 1.443506,1.20311 2.882935,2.51389 3.9375,3.70312 1.054565,1.18924 1.645292,2.31259 1.648438,2.75977 l 0.05664,7.96679 c 0.01296,1.84275 -1.467225,3.31055 -3.320312,3.31055 h -7.835938 -7.523437 c -1.853087,0 -3.30726,-1.46487 -3.320313,-3.32031 l -0.05664,-7.9668 c -0.0037,-0.53151 0.587185,-1.66201 1.646484,-2.85937 1.059299,-1.19737 2.511821,-2.49772 3.970703,-3.6836 2.709307,-2.20231 5.056494,-3.73841 5.431641,-3.98633 z" />
										<Path d="m 23.693359,122.57422 a 0.75,0.75 0 0 0 -0.75,0.75 0.75,0.75 0 0 0 0.75,0.75 h 7.689453 a 0.75,0.75 0 0 0 0.75,-0.75 0.75,0.75 0 0 0 -0.75,-0.75 z" />
									</G>
								</Svg>
							),
						}}
					/>
					<Tab.Screen
						name="Search"
						component={Search}
						options={{
							tabBarIcon: ({ color }) => (
								<Svg fill={color} width="25" height="25" id="lni_lni-diamond-alt" x="0px" y="0px" viewBox="0 0 15.006028 21.97554">
									<G transform="translate(-61.70614,-104.87948)">
										<Path d="m 70.538566,104.87958 c -1.442659,-0.007 -2.902628,0.33947 -4.245736,1.07177 -3.907223,2.1303 -5.610579,6.86063 -3.957897,10.99261 1.5733,3.93351 5.722841,6.1634 9.826274,5.37487 l 2.358511,6.03322 a 0.75,0.75 0 0 0 0.971001,0.4253 0.75,0.75 0 0 0 0.425814,-0.97255 l -2.582272,-6.60322 a 0.75,0.75 0 0 0 -0.468705,-0.43925 0.75,0.75 0 0 0 -0.472322,-0.0346 c -3.576426,0.9583 -7.289558,-0.90353 -8.664588,-4.34134 -1.375028,-3.4378 0.03065,-7.34434 3.281453,-9.11676 3.250803,-1.77241 7.299526,-0.83939 9.444385,2.17868 2.144859,3.01808 1.69488,7.14744 -1.047999,9.63455 a 0.75,0.75 0 0 0 -0.05219,1.05938 0.75,0.75 0 0 0 1.059367,0.0517 c 2.472548,-2.24201 3.395691,-5.61328 2.615344,-8.6894 -0.260117,-1.02536 -0.709435,-2.01853 -1.353925,-2.92541 -1.691783,-2.38054 -4.382344,-3.6855 -7.136514,-3.69951 z" />
									</G>
								</Svg>
							),
						}}
					/>
					<Tab.Screen
						name="MyPurchase"
						component={MyPurchase}
						options={{
							tabBarIcon: ({ color }) => (
								<Svg
									fill={color}
									stroke={color}
									width="25"
									height="25"
									id="lni_lni-diamond-alt"
									x="0px"
									y="0px"
									viewBox="0 0 23.814226 21.972965"
								>
									<G transform="translate(-98.180827,-104.30819)">
										<Path
											fill="none"
											strokeWidth="1.44829"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeOpacity={1}
											d="m 121.26959,113.67451 c 0.0766,-2.32431 -3.83386,-7.85314 -4.63893,-8.25386 -1.30181,-0.64796 -12.18258,-0.36898 -13.07873,0 -0.70509,0.60561 -4.822415,6.31345 -4.638925,8.25386 0.28952,3.06162 8.319085,11.99968 11.178285,11.88086 2.8592,-0.11882 11.08104,-8.92859 11.1783,-11.88086 z"
										/>
										<Path
											strokeWidth="0.19605"
											d="m 104.78674,114.33528 3.06228,2.35781 -0.60196,-3.82059 1.43575,-0.85362 0.85126,6.37286 -1.02457,0.60916 -5.1585,-3.812 z m 5.5492,-3.29926 3.02173,5.08241 -1.32115,0.78549 -3.02174,-5.08241 z m 3.88621,4.56844 -3.02174,-5.08241 2.02218,-1.20228 q 0.82644,-0.48206 1.5751,-0.38907 0.74873,0.0931 1.26972,0.9694 0.53301,0.8965 0.30964,1.59494 -0.22064,0.6877 -1.08343,1.20068 l -0.7523,0.43796 1.0019,1.68515 -1.32115,0.78549 z m -1.06737,-4.80288 0.75342,1.26723 0.22244,-0.13225 q 0.36134,-0.20553 0.61378,-0.45613 0.24849,-0.25722 -0.028,-0.72232 -0.28454,-0.47858 -0.61574,-0.39116 -0.3352,0.0807 -0.71942,0.30913 l -0.22653,0.12538 z"
										/>
									</G>
								</Svg>
							),
						}}
					/>

					<Tab.Screen
						name="Profile"
						component={Profile}
						options={{
							tabBarIcon: ({ color }) => (
								<Svg fill={color} width="25" height="25" id="lni_lni-diamond-alt" x="0px" y="0px" viewBox="0 0 15.006028 21.97554">
									<G transform="translate(-179.86353,-101.73197)">
										<Path d="m 187.36657,101.73197 c -3.11166,0 -5.6503,2.53659 -5.6503,5.64824 0,2.13294 1.19302,3.99646 2.94556,4.95783 -1.01941,0.60547 -1.9161,1.53453 -2.63808,2.67012 -1.31043,2.06117 -2.1073,4.84061 -2.16008,7.92355 a 0.75,0.75 0 0 0 0.73639,0.76378 0.75,0.75 0 0 0 0.76378,-0.73846 c 0.0486,-2.83679 0.79744,-5.37001 1.92546,-7.14426 1.12801,-1.77425 2.57247,-2.74755 4.08037,-2.74609 1.5079,10e-4 2.95094,0.97718 4.07779,2.75384 1.12685,1.77666 1.87537,4.31357 1.92185,7.15047 a 0.75,0.75 0 0 0 0.76378,0.73639 0.75,0.75 0 0 0 0.73638,-0.76172 c -0.0505,-3.08303 -0.84563,-5.86422 -2.15439,-7.92768 -0.72312,-1.14012 -1.62153,-2.07272 -2.64376,-2.67994 1.75269,-0.96137 2.94607,-2.82489 2.94607,-4.95783 0,-3.11165 -2.53915,-5.64824 -5.65082,-5.64824 z m 0,1.50017 c 2.30101,0 4.15065,1.84708 4.15065,4.14807 0,2.30101 -1.84964,4.15065 -4.15065,4.15065 -2.30101,0 -4.15013,-1.84964 -4.15013,-4.15065 0,-2.30099 1.84912,-4.14807 4.15013,-4.14807 z" />
									</G>
								</Svg>
							),
						}}
					/>
				</Tab.Navigator>
			) : (
				<TopTab.Navigator
					tabBarPosition="bottom"
					// tabBar={(props) => <CustomTabBar {...props} />}
					tabBar={(props) => {
						return (
							<LinearGradient
								colors={["#151721", "transparent"]}
								start={{ x: 0, y: 1 }}
								end={{ x: 0, y: 0 }}
								style={styles.gradientTabBar}
							>
								<MaterialTopTabBar
									{...props}
									//  style={{ backgroundColor: "transparent" }}
								/>
							</LinearGradient>
						);
					}}
					screenOptions={topBarProps}
				>
					<TopTab.Screen
						name="Feed"
						component={Feed}
						options={{
							tabBarLabel: ({ color }) => <SvgXml width={"25"} height={"25"} fill={color} color={color} xml={homeIcon} />,
						}}
					/>
					<TopTab.Screen
						name="Search"
						component={Search}
						options={{
							tabBarLabel: ({ color }) => <SvgXml width={"25"} height={"25"} fill={color} color={color} xml={searchIcon} />,
						}}
					/>
					<TopTab.Screen
						name="MyPurchase"
						component={MyPurchase}
						options={{
							title: "purchase",
							tabBarLabel: ({ color }) => (
								<SvgXml width={"25"} height={"25"} fill={color} color={color} stroke={color} xml={premiumIcon} />
							),
						}}
					/>

					<TopTab.Screen
						name="Profile"
						component={Profile}
						options={{
							tabBarLabel: ({ color }) => <SvgXml width={"25"} height={"25"} fill={color} color={color} xml={accountIcon} />,
						}}
					/>
				</TopTab.Navigator>
			)}
		</>
	);
}

// function CustomTabBar(props: MaterialTopTabBarProps) {
// 	return (
// 		<BlurView intensity={100} style={{ width: "100%", height: "100%" }}>
// 			<MaterialTopTabBar {...props} />
// 		</BlurView>
// 	);
// }
