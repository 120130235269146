import { createContext, useContext, useState } from "react";
import { LoadingContext } from "../types/providers/LoadingProvider";

const LoadingContextImpl = createContext<LoadingContext>({} as LoadingContext);
export const LoadingProvider = ({ children }: { children: JSX.Element }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const values = {
		isLoading,
		setIsLoading,
	};

	return (
		<LoadingContextImpl.Provider value={values}>
			{children}
		</LoadingContextImpl.Provider>
	);
};

export const useLoadingContext = () => useContext(LoadingContextImpl);
