import { StatusBar } from "expo-status-bar";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { View, Modal, StyleSheet, Text, Pressable, BackHandler, Alert, Platform, Dimensions } from "react-native";
import { PanGestureHandler, gestureHandlerRootHOC } from "react-native-gesture-handler";
import { AVPlaybackStatus, ResizeMode, Video } from "expo-av";
import { ActivityIndicator } from "react-native";
import VideoControls from "./VideoControls";
import screenfull from "screenfull";

export interface VideoViewerProps extends JSX.IntrinsicAttributes {
	uri: string;
	handleBack: () => void;
}
const VideoViewerContent: React.ComponentType<{ uri: string; handleBack: () => void }> = ({ handleBack, uri }: VideoViewerProps) => {
	const [showBackButton, setShowBackButton] = useState(false);
	const [playbackInstanceInfo, setPlaybackInstanceInfo] = useState<{ position: number; duration: number; state: string }>({
		position: 0,
		duration: 0,
		state: "Buffering",
	});
	let timerRef = React.useRef(null);
	const playbackInstance = useRef<Video>(null);

	const toggleControls = useCallback(() => {
		if (timerRef.current != null) {
			clearTimeout(timerRef.current);
		}
		timerRef.current = setTimeout(() => {
			setShowBackButton(false);
		}, 3000) as any;
	}, []);
	useEffect(() => {
		return () => {
			timerRef.current && clearTimeout(timerRef.current);
		};
	}, []);
	useEffect(() => {
		return () => {
			if (playbackInstance.current) {
				playbackInstance.current.setStatusAsync({
					shouldPlay: false,
				});
			}
		};
	}, []);

	useEffect(() => {
		const handleContextMenu = (e: any) => {
			e.preventDefault();
		};
		if (Platform.OS === "web") document.addEventListener("contextmenu", handleContextMenu);
		return () => {
			if (Platform.OS === "web") document.removeEventListener("contextmenu", handleContextMenu);
		};
	}, []);
	const togglePlay = async () => {
		if (Platform.OS === "web") {
			const shouldPlay = playbackInstanceInfo.state !== "Playing";
			if (playbackInstance.current !== null) {
				await playbackInstance.current.setStatusAsync({
					shouldPlay: shouldPlay,
					...(playbackInstanceInfo.state === "Ended" && { positionMillis: 0 }),
				});
				setPlaybackInstanceInfo({
					...playbackInstanceInfo,
					state: !shouldPlay ? "Paused" : "Playing",
				});
			}
		}
	};

	const updatePlaybackCallback = (status: AVPlaybackStatus) => {
		if (Platform.OS === "web") {
			if (status.isLoaded) {
				setPlaybackInstanceInfo({
					...playbackInstanceInfo,
					position: status.positionMillis,
					duration: status.durationMillis || 0,
					state: status.didJustFinish ? "Ended" : status.isBuffering ? "Buffering" : status.isPlaying ? "Playing" : "Paused",
				});
			} else {
				if (status.isLoaded === false && status.error) {
					const errorMsg = `Encountered a fatal error during playback: ${status.error}`;
					console.log(errorMsg, "error");
				}
			}
		}
	};

	const videoRef = useRef<View>(null);
	async function toggleFullScreen() {
		await screenfull.toggle(videoRef.current as unknown as Element);
	}
	return (
		<>
			<StatusBar backgroundColor="#151721" style="light" hidden={true} translucent />
			<PanGestureHandler
				onActivated={() => {
					handleBack();
				}}
				activeOffsetX={1000000}
				activeOffsetY={100}
			>
				<View style={[styles.video_container]} ref={videoRef}>
					{Platform.OS === "web" ? (
						<Pressable
							onLongPress={(e) => {
								e.preventDefault();
							}}
							onPress={(e) => {
								e.preventDefault();
							}}
							onPointerDown={(e) => {
								e.preventDefault();
							}}
							style={{ flex: 1, width: "100%", height: "100%" }}
						>
							<View pointerEvents="none" style={{ flex: 1, height: "100%" }}>
								<Video
									ref={playbackInstance}
									style={[styles.video]}
									source={{ uri: uri }}
									shouldPlay={true}
									videoStyle={{ width: "100%", height: "100%", position: "relative" }}
									useNativeControls={Platform.OS !== "web"}
									onPlaybackStatusUpdate={updatePlaybackCallback}
									resizeMode={ResizeMode.COVER}
								>
									<ActivityIndicator style={{ flex: 1 }} size={80} color="#DB7BC6" />
								</Video>
							</View>
						</Pressable>
					) : (
						<Video
							ref={playbackInstance}
							style={[styles.video]}
							source={{ uri: uri }}
							shouldPlay={true}
							videoStyle={{ width: "100%", height: "100%", position: "relative" }}
							useNativeControls={true}
							onTouchStart={() => {
								setShowBackButton(true);
							}}
							onTouchMove={() => {
								toggleControls();
							}}
							onTouchEnd={() => {
								toggleControls();
							}}
							onPlaybackStatusUpdate={updatePlaybackCallback}
							resizeMode={ResizeMode.COVER}
						>
							<ActivityIndicator style={{ flex: 1 }} size={80} color="#DB7BC6" />
						</Video>
					)}
					{(showBackButton || Platform.OS === "web") && (
						<Pressable onPress={handleBack} style={styles.back_button}>
							<Text style={{ color: "white" }}>Back</Text>
						</Pressable>
					)}
					{Platform.OS === "web" && (
						<View style={styles.controlsContainer}>
							<VideoControls
								state={playbackInstanceInfo.state}
								playbackInstance={playbackInstance.current}
								playbackInstanceInfo={playbackInstanceInfo}
								setPlaybackInstanceInfo={setPlaybackInstanceInfo}
								togglePlay={togglePlay}
								toggleFullScreen={toggleFullScreen}
							/>
						</View>
					)}
				</View>
			</PanGestureHandler>
		</>
	);
};
const VideoViewerContainer = gestureHandlerRootHOC<VideoViewerProps>(VideoViewerContent);

export default function VideoViewer({ visible, handleBack, uri }: { uri: string; visible: boolean; handleBack: () => void }) {
	return (
		<>
			<Modal
				animationType="fade"
				transparent={true}
				visible={visible}
				statusBarTranslucent={false}
				onRequestClose={() => {
					handleBack();
				}}
				style={{ flex: 1, height: Dimensions.get("window").height }}
			>
				<View style={{ flex: 1, backgroundColor: "#151721" }}>
					<VideoViewerContainer uri={uri} handleBack={handleBack} />
				</View>
			</Modal>
		</>
	);
}
const styles = StyleSheet.create({
	video_container: {
		width: "100%",
		height: "100%",
		position: "relative",
		flex: 1,
		backgroundColor: "#151721",
	},

	back_button: {
		position: "absolute",
		top: 15,
		left: 15,
		color: "white",
		marginVertical: 15,
		textAlign: "center",
		fontSize: 17,
		marginLeft: 15,
	},
	video: {
		position: "relative",
		// flex: 1,
		width: "100%",
		height: "100%",
		// borderRadius: 32,
		backgroundColor: "#151721",
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",
		textAlignVertical: "center",
		// height: 550,
	},
	controlsContainer: {
		width: "100%",
		position: "absolute",
		bottom: 10,
	},
});
