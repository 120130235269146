import * as FileSystem from "expo-file-system";

const IMAGE_CACHE_FOLDER = `${FileSystem.cacheDirectory}images/`;

export async function findImageInCache(imageName: string): Promise<{ exists: boolean; err: boolean; path?: string }> {
	try {
		const uri = `${IMAGE_CACHE_FOLDER}${imageName}`;

		let info = await FileSystem.getInfoAsync(uri);
		return { exists: info.exists, err: false, path: info.uri };
	} catch (error) {
		return {
			exists: false,
			err: true,
		};
	}
}
export async function getImageFolderExists() {
	const imageFolder = await FileSystem.getInfoAsync(IMAGE_CACHE_FOLDER);
	return imageFolder.exists;
}
export async function createImageFolderExists() {
	await FileSystem.makeDirectoryAsync(IMAGE_CACHE_FOLDER);
}
export async function cleanupChace() {
	const cachedFiles = await FileSystem.readDirectoryAsync(`${IMAGE_CACHE_FOLDER}`);
	let position = 0;
	let results: { file: string; modificationTime: number; size: number }[] = [];
	const batchSize = 10;

	while (position < cachedFiles.length) {
		const itemsForBatch = cachedFiles.slice(position, position + batchSize);
		results = [
			...results,
			...(await Promise.all(
				itemsForBatch.map(async (file) => {
					const info = await FileSystem.getInfoAsync(`${IMAGE_CACHE_FOLDER}${file}`);
					return Promise.resolve({ file, modificationTime: (info as any).modificationTime, size: (info as any).size });
				})
			)),
		];
		position += batchSize;
	}

	const sorted = results.sort((a, b) => a.modificationTime - b.modificationTime);

	for (let i = 0; sorted.length - i > 10; i += 1) {
		await FileSystem.deleteAsync(`${IMAGE_CACHE_FOLDER}${sorted[i].file}`, { idempotent: true });
	}
}

export async function cleanupCameraCache() {
	try {
		FileSystem.deleteAsync(`${FileSystem.cacheDirectory}/Camera`, { idempotent: true });
	} catch (error) {
		console.log(error);
	}
}

export async function cleanupImagePickerCache() {
	try {
		FileSystem.deleteAsync(`${FileSystem.cacheDirectory}/ImagePicker`, { idempotent: true });
	} catch (error) {
		console.log(error);
	}
}

export async function cacheImage(uri: string, imageName: string) {
	try {
		const imageFolderExist = await getImageFolderExists();
		if (!imageFolderExist) {
			await createImageFolderExists();
		}
		const cacheUri = `${IMAGE_CACHE_FOLDER}${imageName}`;
		const downloadImage = FileSystem.createDownloadResumable(uri, cacheUri, {});
		const downloaded = await downloadImage.downloadAsync();
		if (downloaded == null) {
			throw new Error("Error downloading image");
		}
		return {
			cached: true,
			err: false,
			path: downloaded.uri,
		};
	} catch (error) {
		return {
			cached: false,
			err: true,
			msg: error,
		};
	}
}
