import Header from "./components/common/Header";
import { useAuthenticationContext } from "./providers/AuthenticationProvider";
import { createStackNavigator } from "@react-navigation/stack";
import { useEffect } from "react";
import { Platform } from "react-native";
const Stack = createStackNavigator();
import { useNavigationContainerRef } from "@react-navigation/native";

import Auth from "./pages/Auth";
import HomeNavigator from "./HomeNavigator";
import CreatorPage from "./pages/CreatorPage";
import Receive from "./pages/profile/Receive";
import BecomeACreator from "./pages/profile/BecomeACreator";
import WalletInformationPage from "./pages/profile/WalletInformationPage";
import PostContent from "./pages/profile/PostContent";
import ModifyProfile from "./pages/profile/ModifyProfile";
import { Snackbars } from "./components/snackbar/SnackBars";
import LoadingComponent from "./components/loading/LoadingComponent";
import { useLoadingContext } from "./providers/LoadingProvider";
import WalletCreation from "./pages/Auth/WalletCreation";
import WalletImport from "./pages/Auth/WalletImport";
import { useRedirectionContext } from "./providers/RedirectionProvider";
import * as Linking from "expo-linking";
import { useWalletContext } from "./providers/WalletProvider";
import { useTranslation } from "react-i18next";
import DeleteAccount from "./pages/DeleteAccount";

export default function Root({
	creatorAddress,
	creatorName,
	navigation,
}: {
	creatorAddress: string | null | undefined;
	creatorName: string | null | undefined;
	navigation: any;
}) {
	const { isLoading } = useLoadingContext();
	const { isAuthenticated } = useAuthenticationContext();
	const { walletInfo } = useWalletContext();
	const url = Linking.useURL();
	const { t } = useTranslation();
	useEffect(() => {
		if (isAuthenticated && creatorAddress != null && creatorName != null) {
			navigation.navigate("Creator", { address: creatorAddress, name: creatorName });
		}
	}, [isAuthenticated]);
	useEffect(() => {
		if (url != null && Platform.OS === "web") {
			const { hostname, path, queryParams } = Linking.parse(url);
			if (
				path !== "/" &&
				path !== "" &&
				path !== "Import Wallet" &&
				path !== "Quick Access" &&
				path !== "records/user" &&
				path !== "Authentication" &&
				walletInfo == null
			) {
				// navigation.navigate("Authentication");
				Linking.openURL(Linking.createURL("/"));
			}
		}
	}, [url]);
	return (
		<>
			{/* <Header /> */}
			<Stack.Navigator screenOptions={{ headerStyle: { borderColor: "transparent", borderWidth: 0 } }}>
				<Stack.Screen
					name="Authentication"
					options={{
						title: "",
						headerStyle: {
							height: 0,
							borderBottomWidth: 0,
						},
					}}
					component={Auth}
				/>
				<Stack.Screen
					options={{
						title: "",
						headerStyle: {
							height: 0,
							borderBottomWidth: 0,
						},
						headerLeftContainerStyle: {
							display: "none",
						},
						headerBackTitleVisible: false,
					}}
					name="Home"
					component={HomeNavigator}
				/>
				<Stack.Screen
					options={({ route }) => ({
						title: (route.params as any)?.name,

						headerStyle: {
							backgroundColor: "#151721",
							elevation: 0,
							shadowOpacity: 0,
							borderBottomWidth: 0,
						},

						animationEnabled: true,
						headerTintColor: "#DB7BC6",
						headerTitleStyle: {
							fontWeight: "bold",
							color: "white",
						},
					})}
					name="Creator"
					component={CreatorPage}
				/>
				<Stack.Screen
					name="Receive"
					options={({ route }) => ({
						title: t("pages.receive"),
						headerStyle: {
							backgroundColor: "#151721",
							elevation: 0,
							shadowOpacity: 0,
							borderBottomWidth: 0,
						},

						animationEnabled: true,
						headerTintColor: "#DB7BC6",
						headerTitleStyle: {
							fontWeight: "bold",
							color: "white",
						},
					})}
					component={Receive}
				/>
				<Stack.Screen
					name="Become A Creator"
					options={({ route }) => ({
						title: t("pages.become_a_creator"),
						headerStyle: {
							backgroundColor: "#151721",
							elevation: 0,
							shadowOpacity: 0,
							borderBottomWidth: 0,
						},
						cardStyle: {
							flex: 1,
							height: "100%",
						},

						animationEnabled: true,
						headerTintColor: "#DB7BC6",
						headerTitleStyle: {
							fontWeight: "bold",
							color: "white",
						},
					})}
					component={BecomeACreator}
				/>
				<Stack.Screen
					name="Wallet Information"
					options={({ route }) => ({
						title: t("pages.wallet_info"),
						headerStyle: {
							backgroundColor: "#151721",
							elevation: 0,
							shadowOpacity: 0,
							borderBottomWidth: 0,
						},

						animationEnabled: true,
						headerTintColor: "#DB7BC6",
						headerTitleStyle: {
							fontWeight: "bold",
							color: "white",
						},
					})}
					component={WalletInformationPage}
				/>
				<Stack.Screen
					name="Post Content"
					options={({ route }) => ({
						title: t("pages.post_content"),
						headerStyle: {
							backgroundColor: "#151721",
							elevation: 0,
							shadowOpacity: 0,
							borderBottomWidth: 0,
						},
						cardStyle: {
							flex: 1,
							height: "100%",
						},

						animationEnabled: true,
						headerTintColor: "#DB7BC6",
						headerTitleStyle: {
							fontWeight: "bold",
							color: "white",
						},
					})}
					component={PostContent}
				/>
				<Stack.Screen
					name="Modify Profile"
					options={({ route }) => ({
						title: t("pages.modify_profile"),
						headerStyle: {
							backgroundColor: "#151721",
							elevation: 0,
							shadowOpacity: 0,
							borderBottomWidth: 0,
						},
						cardStyle: {
							flex: 1,
							height: "100%",
						},

						animationEnabled: true,
						headerTintColor: "#DB7BC6",
						headerTitleStyle: {
							fontWeight: "bold",
							color: "white",
						},
					})}
					component={ModifyProfile}
				/>
				<Stack.Screen
					name="Quick Access"
					options={({ route }) => ({
						title: t("pages.quick_access"),
						headerStyle: {
							backgroundColor: "#151721",
							elevation: 0,
							shadowOpacity: 0,
							borderBottomWidth: 0,
						},

						animationEnabled: true,
						headerTintColor: "#DB7BC6",
						headerTitleStyle: {
							fontWeight: "bold",
							color: "white",
						},
					})}
					component={WalletCreation}
				/>
				<Stack.Screen
					name="Import Wallet"
					options={({ route }) => ({
						title: t("pages.import_wallet"),
						headerStyle: {
							backgroundColor: "#151721",
							elevation: 0,
							shadowOpacity: 0,
							borderBottomWidth: 0,
						},
						cardStyle: {
							flex: 1,
							height: "100%",
						},

						animationEnabled: true,
						headerTintColor: "#DB7BC6",
						headerTitleStyle: {
							fontWeight: "bold",
							color: "white",
						},
					})}
					component={WalletImport}
				/>
				<Stack.Screen
					name="DeleteAccount"
					options={({ route }) => ({
						title: t("pages.delete_account"),
						headerStyle: {
							backgroundColor: "#151721",
							elevation: 0,
							shadowOpacity: 0,
							borderBottomWidth: 0,
						},
						cardStyle: {
							flex: 1,
							height: "100%",
						},

						animationEnabled: true,
						headerTintColor: "#DB7BC6",
						headerTitleStyle: {
							fontWeight: "bold",
							color: "white",
						},
					})}
					component={DeleteAccount}
				/>
			</Stack.Navigator>
			{/* </>
			// ) : (
			// 	<Auth />
			// )} */}
			<Snackbars />
			<LoadingComponent isLoading={isLoading} />
		</>
	);
}
