import { View, Text, StyleSheet, TouchableOpacity, Pressable } from "react-native";
import { useWalletContext } from "../providers/WalletProvider";
import { useEffect, useState } from "react";
import Pin from "./Pin";
import { useLoadingContext } from "../providers/LoadingProvider";
import { useSnackbar } from "../providers/SnackBarProvider";
import { useTranslation } from "react-i18next";
import { useAuthenticationContext } from "../providers/AuthenticationProvider";
export default function DeleteAccount({ navigation }: { navigation: any }) {
	const { deleteAccount, isCorrectPin, setWalletInfo } = useWalletContext();
	const [wantToDeleteAccount, setWantToDeleteAccount] = useState<boolean>(false);
	const [pin, setPin] = useState<number[]>([]);
	const [biometricsAuth, setBiometricsAuth] = useState<boolean>(false);
	const { setIsLoading } = useLoadingContext();
	const { openSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const { setIsAuthenticated, setHasWallet } = useAuthenticationContext();

	async function handleDeleteAccount() {
		await deleteAccount();
		navigation.navigate("Authentication");
	}
	useEffect(() => {
		(async () => {
			try {
				if (pin.length === 5) {
					setIsLoading(true);
					const isCorrectPin_ = await isCorrectPin(pin.join(""));
					if (isCorrectPin_) {
						setHasWallet(false);
						setIsAuthenticated(false);
						setWalletInfo(null);
						await handleDeleteAccount();
						openSnackbar(t("common.success"), t("common.account_deleted"), "success");
					} else {
						openSnackbar(t("common.error"), t("errors.incorrect_pin"), "error");
					}
					setIsLoading(false);
				}

				if (biometricsAuth) {
					setIsLoading(true);
					setHasWallet(false);
					setIsAuthenticated(false);
					setWalletInfo(null);
					await handleDeleteAccount();
					openSnackbar(t("common.success"), t("common.account_deleted"), "success");

					setIsLoading(false);
				}
			} catch (err) {
				const error = err as Error;
				let errorMessage = t("errors.something_went_wrong");
				openSnackbar(t("common.error"), errorMessage, "error");
			}
		})();
	}, [pin, biometricsAuth]);
	return (
		<>
			{wantToDeleteAccount ? (
				<Pin pin={pin} setPin={setPin} isRegistration={false} setLoggedUsingBiometrics={setBiometricsAuth} resetPin={false} />
			) : (
				<View style={{ flex: 1, justifyContent: "center", padding: 16, backgroundColor: "#151721" }}>
					<Text style={{ fontFamily: "Gilroy-Bold", fontSize: 24, color: "white", alignSelf: "center" }}>{t("deleteAccount.text")}</Text>
					<View
						style={{
							flex: 1,
							justifyContent: "space-between",
							width: "100%",
							flexDirection: "row",
							maxWidth: 650,
							alignItems: "flex-end",
							marginBottom: 64,
							paddingHorizontal: 16,
						}}
					>
						<Pressable
							style={{ backgroundColor: "#232839", padding: 16, paddingHorizontal: 32, borderRadius: 16 }}
							onPress={() => {
								navigation.navigate("Profile");
							}}
						>
							<Text style={{ fontFamily: "Gilroy-Bold", fontSize: 20, color: "white" }}>{t("deleteAccount.back")}</Text>
						</Pressable>
						<Pressable
							style={{ backgroundColor: "#DB7BC6", padding: 16, paddingHorizontal: 32, borderRadius: 16 }}
							onPress={() => {
								setWantToDeleteAccount(true);
							}}
						>
							<Text style={{ fontFamily: "Gilroy-Bold", fontSize: 20, color: "white" }}>{t("pin.continue")}</Text>
						</Pressable>
					</View>
				</View>
			)}
		</>
	);
}
