import { useState, useEffect } from "react";
import {
	View,
	Modal,
	StyleSheet,
	Text,
	TouchableWithoutFeedback,
	Pressable,
	BackHandler,
	Alert,
	TextInput,
} from "react-native";
import { Feather } from "@expo/vector-icons";

import * as Clipboard from "expo-clipboard";
import { A } from "@expo/html-elements";
import { useTranslation } from "react-i18next";

export default function PurchaseModal({
	isOpen,
	walletAddress,
	handleBack,
}: {
	isOpen: boolean;
	walletAddress: string;
	handleBack: () => void;
}) {
	const [hasCopied, setHasCopied] = useState<boolean>(false);
	const [matic, setMatic] = useState<number>(20);
	const { t, i18n } = useTranslation();
	useEffect(() => {
		setHasCopied(false);
	}, [isOpen]);
	async function copyToClipboard() {
		await Clipboard.setStringAsync(walletAddress);
	}
	return (
		<>
			<Modal
				animationType="fade"
				transparent={true}
				visible={isOpen}
				statusBarTranslucent={false}
				onRequestClose={() => {
					handleBack();
				}}
			>
				<TouchableWithoutFeedback
					style={styles.centeredView}
					onPress={() => handleBack()}
				>
					<View style={styles.centeredView}>
						<Pressable style={styles.modalView}>
							<Text
								style={{
									fontSize: 24,
									fontFamily: "Gilroy-Bold",
									color: "white",
								}}
							>
								{t("purchaseMatic.title")}
							</Text>
							<Text
								style={{
									fontSize: 16,
									fontFamily: "Gilroy-Bold",
									color: "white",
								}}
							>
								{t("purchaseMatic.modal_text")}
							</Text>

							<Pressable
								onPress={async () => {
									await copyToClipboard();
									setHasCopied(true);
								}}
								style={{
									flexDirection: "row",
									gap: 8,
									alignItems: "center",
									alignSelf: "flex-start",
									borderRadius: 32,
									backgroundColor: "rgba(219, 123, 198, 0.4)",
									paddingHorizontal: 16,
									paddingVertical: 8,
								}}
							>
								<Feather name="copy" size={20} color="white" />
								<Text style={{ color: "white", fontSize: 12 }}>
									{walletAddress.substring(0, 4).toLocaleUpperCase()}...
									{walletAddress
										.substring(walletAddress.length - 4)
										.toLocaleUpperCase()}
								</Text>
							</Pressable>

							<A
								disabled={!hasCopied}
								href={`https://exchange.mercuryo.io/?currency=MATIC&fiat_currency=USD&network=POLYGON`}
								target="_blank"
								style={{
									backgroundColor: "#DB7BC6",
									opacity: hasCopied ? 1 : 0.2,
									alignSelf: "flex-end",
									borderRadius: 16,
									paddingHorizontal: 16,
									paddingVertical: 8,
									justifyContent: "center",
									flexDirection: "row",
									textAlign: "center",
								}}
							>
								<Text>{t("common.procede")}</Text>
							</A>
						</Pressable>
					</View>
				</TouchableWithoutFeedback>
			</Modal>
		</>
	);
}
const styles = StyleSheet.create({
	centeredView: {
		position: "relative",
		height: "100%",
		width: "100%",
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "#000a",
	},
	modalView: {
		display: "flex",
		gap: 16,
		width: "80%",
		padding: 16,
		backgroundColor: "#151721",
		borderRadius: 16,
		justifyContent: "center",
		alignItems: "center",
	},
});
