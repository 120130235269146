import { StatusBar } from "expo-status-bar";
import React, { createRef, useRef, useState, useEffect } from "react";
import { View, Modal, StyleSheet, Text, Image, Pressable, Platform, Dimensions } from "react-native";
import {
	PanGestureHandler,
	gestureHandlerRootHOC,
	State,
	PinchGestureHandler,
	HandlerStateChangeEvent,
	PinchGestureHandlerEventPayload,
	PinchGestureHandlerGestureEvent,
	PanGestureHandlerGestureEvent,
} from "react-native-gesture-handler";
// import { Animated } from "react-native";
import "setimmediate";

import Animated, { event, runOnJS, useAnimatedGestureHandler, useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";
const { width, height } = Dimensions.get("window");
export interface ImageViewerProps extends JSX.IntrinsicAttributes {
	uri: string;
	fullImage: boolean;
	handleBack: () => void;
}
const ImageViewerContent: React.ComponentType<{ uri: string; fullImage: boolean; handleBack: () => void }> = ({
	fullImage,
	handleBack,
	uri,
}: ImageViewerProps) => {
	const scale = useSharedValue(1);
	const translateX = useSharedValue(0);
	const translateY = useSharedValue(0);

	const pinchRef = createRef();
	const panRef = createRef();

	const onPinchEvent_ = useAnimatedGestureHandler<PinchGestureHandlerGestureEvent>({
		onActive: (event) => {
			scale.value = event.scale;

			translateX.value = event.focalX;
			translateY.value = event.focalY;
		},
		onFinish: () => {
			if (Platform.OS === "web") runOnJS(withTiming)(1);
			else scale.value = withTiming(1);
		},
		onCancel: () => {
			if (Platform.OS === "web") runOnJS(withTiming)(1);
			else scale.value = withTiming(1);
		},
		onEnd: (event) => {
			if (Platform.OS === "web") runOnJS(withTiming)(1);
			else scale.value = withTiming(1);
		},
		onFail: (event) => {
			scale.value = withTiming(1);
		},
	});
	const onPanEvent_ = useAnimatedGestureHandler<PanGestureHandlerGestureEvent>({
		onActive: (event) => {
			if (event.numberOfPointers === 1) runOnJS(handleBack)();
		},
	});

	const rStyle = useAnimatedStyle(() => {
		return {
			transform: [
				{ translateX: translateX.value },
				{ translateY: translateY.value },
				{ translateX: -width / 2 },
				{ translateY: -height / 2 },
				{ scale: scale.value },
				{ translateX: -translateX.value },
				{ translateY: -translateY.value },
				{ translateX: +width / 2 },
				{ translateY: +height / 2 },
			],
		};
	});

	return (
		<>
			<StatusBar backgroundColor="#151721" style="light" />
			<PanGestureHandler
				onGestureEvent={onPanEvent_}
				activeOffsetX={1000000}
				activeOffsetY={100}
				ref={panRef}
				simultaneousHandlers={[pinchRef]}
			>
				<Animated.View style={styles.image_container} ref={pinchRef} simultaneousHandlers={[panRef]}>
					<PinchGestureHandler
						onGestureEvent={onPinchEvent_}
						onCancelled={() => {
							scale.value = withTiming(1);
						}}
						onEnded={() => {
							scale.value = withTiming(1);
						}}
						onFailed={() => {
							scale.value = withTiming(1);
						}}
					>
						<Animated.Image
							source={{ uri: uri }}
							style={[
								{
									width: "100%",
									height: "100%",
									// transform: [{ scale: scale.current }, { translateX: translateX.current }, { translateY: translateY.current }],
								},
								rStyle,
							]}
							resizeMode="contain"
						/>
					</PinchGestureHandler>

					<Pressable onPress={handleBack} style={styles.back_button}>
						<Text style={{ color: "white" }}>Back</Text>
					</Pressable>
				</Animated.View>
			</PanGestureHandler>
		</>
	);
};
const ImageViewerContainer = gestureHandlerRootHOC<ImageViewerProps>(ImageViewerContent);

export default function ImageViewer({ fullImage, handleBack, uri }: { uri: string; fullImage: boolean; handleBack: () => void }) {
	return (
		<>
			<Modal
				animationType="fade"
				transparent={true}
				visible={fullImage}
				statusBarTranslucent={false}
				onRequestClose={() => {
					handleBack();
				}}
			>
				<View style={{ flex: 1, backgroundColor: "#151721" }}>
					<ImageViewerContainer uri={uri} fullImage={fullImage} handleBack={handleBack} />
				</View>
			</Modal>
		</>
	);
}
const styles = StyleSheet.create({
	image_container: {
		width: "100%",
		height: "100%",
		position: "relative",
		backgroundColor: "#151721",
	},

	back_button: {
		position: "absolute",
		top: 15,
		left: 15,
		color: "white",
		marginVertical: 15,
		textAlign: "center",
		fontSize: 17,
		marginLeft: 15,
	},
});
