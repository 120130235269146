import { createContext, useContext } from "react";

import axios, { AxiosError, AxiosHeaders, AxiosRequestConfig, AxiosResponse } from "axios";

import { NetworkContextType } from "../types/providers/NetworkProvider";

const NetworkContext = createContext<NetworkContextType>({} as NetworkContextType);
export const NetworkProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
	const baseUrl = "https://app.netlyfans.com/";
	const header: AxiosRequestConfig = { headers: {} };

	async function makePostCall<T = any, D = any>(url: string, body: any, headers?: AxiosHeaders): Promise<AxiosResponse<T, D>> {
		if (url === undefined) {
			console.error("url param is undefined in makePost function");
		}
		if (headers != null) {
			header.headers = headers;
		}
		if (header.headers == null) {
			return Promise.reject("the headers are undefined");
		}

		try {
			const result = await axios.post<T>(`${baseUrl}${url}`, body, {
				headers: header.headers,
			});
			return result;
		} catch (error) {
			const err = error as AxiosError<T, D>;

			return err.response as AxiosResponse<T, D>;
		}
	}
	async function makePostCallWithUpload<T = any, D = any>(
		url: string,
		body: any,
		headers?: AxiosHeaders,
		uploadProgress?: (progress: number, total: number) => void
	): Promise<AxiosResponse<T, D>> {
		if (url === undefined) {
			console.error("url param is undefined in makePost function");
		}
		if (headers != null) {
			header.headers = headers;
		}
		if (header.headers == null) {
			return Promise.reject("the headers are undefined");
		}

		try {
			const result = await axios.post<T>(`${baseUrl}${url}`, body, {
				onUploadProgress(progressEvent) {
					if (uploadProgress != null && progressEvent.total != null) {
						uploadProgress(progressEvent.loaded, progressEvent.total);
					}
				},
				headers: header.headers,
			});
			return result;
		} catch (error) {
			const err = error as AxiosError<T, D>;

			return err.response as AxiosResponse<T, D>;
		}
	}

	async function makeGetCall<T = any>(path: string): Promise<AxiosResponse<T, any>> {
		if (path === undefined) {
			return Promise.reject("path param is undefined in makeGet function");
		}
		if (header.headers == null) {
			return Promise.reject("the headers are undefined");
		}

		try {
			const result = await axios.get<T>(`${baseUrl.concat(path)}`, header);
			return result;
		} catch (error) {
			const err = error as AxiosError<T>;

			return err.response as AxiosResponse<T>;
		}
	}

	const networkProviderData: NetworkContextType = {
		makePost: makePostCall,
		makePostContent: makePostCallWithUpload,
		makeGet: makeGetCall,
	};

	return <NetworkContext.Provider value={networkProviderData}>{children}</NetworkContext.Provider>;
};
export const useNetwork = () => useContext(NetworkContext);
