import { createContext, useContext, useState, useEffect } from "react";
import { CustomImageType } from "../types/Images";
import {
	cacheImage,
	cleanupChace,
	createImageFolderExists,
	findImageInCache,
	getImageFolderExists,
} from "../utils/cache/CacheUtils";
import { Platform } from "react-native";
import { UserContext } from "../types/providers/UserProvider";
import { useBlockchainContext } from "./BlockchainProvider";
import { useWalletContext } from "./WalletProvider";
import { CreatorType } from "../types/creator/Creator";

const UserContextImpl = createContext<UserContext>({} as UserContext);
export const UserProvider = ({ children }: { children: JSX.Element }) => {
	const [isCreator, setIsCreator] = useState<boolean>(false);
	const [userInformation, setUserInformation] = useState<CreatorType>(
		{} as CreatorType
	);

	const { isAddressCreator, getCreatorByAddress } = useBlockchainContext();
	const { walletInfo } = useWalletContext();

	useEffect(() => {
		if (walletInfo != null) handleIsCreator(walletInfo.address);
	}, [walletInfo]);
	useEffect(() => {
		if (isCreator && walletInfo != null) {
			handleSetCreatorInformation(walletInfo?.address);
		}
	}, [isCreator]);

	const handleIsCreator = async (address: string) => {
		const isCreator = await isAddressCreator(address);
		setIsCreator(isCreator);
	};
	const handleSetCreatorInformation = async (address: string) => {
		const creator = await getCreatorByAddress(address);
		setUserInformation(creator);
	};

	const values: UserContext = {
		isCreator,
		userInformation,
		handleIsCreator,
	};

	return (
		<UserContextImpl.Provider value={values}>
			{children}
		</UserContextImpl.Provider>
	);
};

export const useUserContext = () => useContext(UserContextImpl);
