import { createContext, useContext, useState, useEffect } from "react";
import { AuthenticationContext } from "../types/providers/AuthenticationProvider";
import AsyncStorageStatic from "@react-native-async-storage/async-storage";
const AuthenticationContextImpl = createContext<AuthenticationContext>({} as AuthenticationContext);
export const AuthenticationProvider = ({ children }: { children: JSX.Element }) => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [hasWallet, setHasWallet] = useState(false);

	useEffect(() => {
		hasWalletStored();
		return () => {};
	}, []);

	const hasWalletStored = async () => {
		let hasAccont = false;
		const address = await AsyncStorageStatic.getItem("@walletAddress");
		if (address != null) hasAccont = true;
		setHasWallet(hasAccont);
		return hasAccont;
	};

	const hasEnabledBiometrics = async () => {
		let biometricsEnabled = false;
		const biometrics = await AsyncStorageStatic.getItem("@useBiometrics");
		if (biometrics != null && biometrics === "true") biometricsEnabled = true;
		return biometricsEnabled;
	};

	const values = {
		isAuthenticated,
		setIsAuthenticated,
		hasWalletStored,
		hasWallet,
		setHasWallet,
		hasEnabledBiometrics,
	};

	return <AuthenticationContextImpl.Provider value={values}>{children}</AuthenticationContextImpl.Provider>;
};

export const useAuthenticationContext = () => useContext(AuthenticationContextImpl);
