import { useState, Dispatch, SetStateAction } from "react";
import { Text, ScrollView, StyleSheet, View, TextInput, Platform, Pressable, Image, Dimensions } from "react-native";
import * as ImagePicker from "expo-image-picker";
import * as FileSystem from "expo-file-system";
import { Snackbar } from "react-native-paper";
import { useSnackbar } from "../../providers/SnackBarProvider";
import { useUserContext } from "../../providers/UserProvider";
import { useBlockchainContext } from "../../providers/BlockchainProvider";
import { CreatorType } from "../../types/creator/Creator";
import { useNetwork } from "../../providers/NetworkProvider";
import FormData from "form-data";
import { useWalletContext } from "../../providers/WalletProvider";
import { Path, Svg, SvgXml } from "react-native-svg";
import { Feather } from "@expo/vector-icons";
import { Entypo } from "@expo/vector-icons";
import { FontAwesome5 } from "@expo/vector-icons";
import { Switch } from "react-native-switch";

import { useTranslation } from "react-i18next";
import { fileTypeToExtension } from "../../utils/imageController";
import { useLoadingContext } from "../../providers/LoadingProvider";

export default function ModifyProfile({ navigation }: { navigation: any }) {
	const { userInformation } = useUserContext();
	const [profileImage, setProfileImage] = useState<ImagePicker.ImagePickerResult>({} as ImagePicker.ImagePickerResult);
	const [profileBackgroundImage, setProfileBackgroundImage] = useState<ImagePicker.ImagePickerResult>({} as ImagePicker.ImagePickerResult);
	const [username, setUsername] = useState<string>(userInformation.name);
	const [descritpion, setDescription] = useState<string>(userInformation.bio);
	const [twitter, setTwitter] = useState<string>(userInformation.twitter);
	const [instagram, setInstagram] = useState<string>(userInformation.instagram);
	const [telegram, setTelegram] = useState<string>(userInformation.telegram);
	const [youtube, setyoutube] = useState<string>(userInformation.social4);
	const [isEnabled, setIsEnabled] = useState(false);
	const toggleSwitch = () => setIsEnabled((previousState) => !previousState);
	const { t } = useTranslation();
	const { openSnackbar } = useSnackbar();
	const { modifyProfile } = useBlockchainContext();
	const { makePost } = useNetwork();
	const { walletInfo } = useWalletContext();
	const { setIsLoading } = useLoadingContext();
	async function pickContent(setImage: Dispatch<SetStateAction<ImagePicker.ImagePickerResult>>) {
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
			allowsEditing: true,
			quality: 1,
		});

		if (!result.canceled) {
			setImage(result);
		}
	}

	async function handleUpdateInfoInSC(username: string, description: string, profile_image: string, background_image: string) {
		if (walletInfo == null) throw new Error("Wallet not connected");

		const creator: CreatorType = {
			address: walletInfo.address,
			bio: description,
			profileImageURL: profile_image,
			coverImageURL: background_image,
			twitter: twitter,
			telegram: telegram,
			instagram: instagram,
			social4: youtube,
			name: username,
		};
		try {
			await modifyProfile(creator);
		} catch (err) {
			console.log(err);
			throw new Error("Error updating the information in the Smart Contract");
		}
	}
	async function handleUpdateInfoInStorage() {
		let returnValue = {
			profile: "",
			cover: "",
			username: "",
			description: "",
			ig: "",
			tw: "",
			tg: "",
			yt: "",
			error: false,
			errMessage: "",
		};
		try {
			let formData = new FormData();
			let freeUri: string = "";
			let paidUri: string = "";
			let instagramValue: string = instagram;
			let telegramValue: string = telegram;
			let twitterValue: string = twitter;
			let youtubeValue: string = youtube;
			if (Platform.OS === "web") {
				if (profileImage.assets != null) {
					const base64 = profileImage.assets[0].uri;
					const res = await fetch(base64);
					const blob = await res.blob();
					const file = new File([blob], "profile" + fileTypeToExtension(blob.type), { type: blob.type });
					formData.append("profilePhoto", file);
				} else {
					throw new Error("no back photo");
				}
			}
			if (Platform.OS === "web") {
				if (profileBackgroundImage.assets != null) {
					const base64 = profileBackgroundImage.assets[0].uri;
					const res = await fetch(base64);
					const blob = await res.blob();
					const file = new File([blob], "cover" + fileTypeToExtension(blob.type), { type: blob.type });
					formData.append("coverPhoto", file);
				} else {
					throw new Error("no front photo");
				}
			}

			if (username === "") {
				throw new Error("Inserisci un username");
			}
			if (descritpion === "") {
				throw new Error("Inserisci una descrizione");
			}
			if (instagramValue === "") {
				instagramValue = "-";
			}
			if (telegramValue === "") {
				telegramValue = "-";
			}
			if (twitterValue === "") {
				twitterValue = "-";
			}
			if (youtubeValue === "") {
				youtubeValue = "-";
			}
			if (Platform.OS !== "web") {
				if (profileImage.assets != null) {
					freeUri = profileImage.assets[0].uri;
				} else {
					throw new Error("Carica la foto del profilo");
				}
				if (profileBackgroundImage.assets != null) {
					paidUri = profileBackgroundImage.assets[0].uri;
				} else {
					throw new Error("Carica la foto della cover");
				}
				const backType = freeUri.split(".").slice(-1)[0];
				const freeType = paidUri.split(".").slice(-1)[0];
				formData.append("profilePhoto", { uri: freeUri, name: "front." + freeType, type: "image/" + freeType });
				formData.append("coverPhoto", { uri: paidUri, name: "back." + backType, type: "image/" + backType });
			}
			formData.append("description_profile", descritpion);
			formData.append("username", username);
			formData.append("mobile", true);
			formData.append("ig", instagramValue);
			formData.append("tg", telegramValue);
			formData.append("tw", twitterValue);
			formData.append("yt", youtubeValue);
			const response = await makePost("upload2.php", formData, {
				Accept: "application/json",
				"Content-Type": "multipart/form-data",
			} as any);

			let responseCookie = { errid: 0, profile: "", cover: "", username: "", description: "", ig: "", tw: "", tg: "", yt: "" };
			const data_ = String(response.data);

			if (data_.length === 1) {
				responseCookie.errid = Number(data_);
			} else {
				const data = data_.split(";");
				responseCookie.profile = String(data[0]);
				responseCookie.cover = String(data[1]);
				responseCookie.username = String(data[2]);
				responseCookie.description = String(data[3]);
				responseCookie.ig = String(data[4]);
				responseCookie.tw = String(data[5]);
				responseCookie.tg = String(data[6]);
				responseCookie.yt = String(data[7]);
				responseCookie.errid = Number(data[data.length - 1]);
			}
			if (responseCookie.errid === 0 && responseCookie.profile != null && responseCookie.cover != null) {
				returnValue.profile = responseCookie.profile.split("\n").join("");
				returnValue.cover = responseCookie.cover;
				returnValue.username = responseCookie.username;
				returnValue.description = responseCookie.description;
				returnValue.ig = responseCookie.ig;
				returnValue.tw = responseCookie.tw;
				returnValue.tg = responseCookie.tg;
				returnValue.yt = responseCookie.yt;
			} else {
				returnValue.error = true;
				switch (responseCookie.errid) {
					case 1:
						returnValue.errMessage = t("errors.profile_photo_exeeds_10MB");
						break;
					case 2:
						returnValue.errMessage = t("errors.cover_photo_exeeds_10MB");
						break;
					case 3:
						returnValue.errMessage = t("errors.profile_photo_format_not_supported");
						break;
					case 4:
						returnValue.errMessage = t("errors.cover_photo_format_not_supported");
						break;
					case 5:
						returnValue.errMessage = t("errors.something_went_wrong_during_file_saving");
						break;
					default:
						returnValue.errMessage = t("errors.weird_contact_us");
				}
			}
		} catch (err) {
			console.log(err);
			const error = err as Error;
			if (error.message === "Carica la foto del profilo") {
				returnValue.errMessage = t("errors.insert_profile_image");
			} else if (error.message === "Carica la foto della cover") {
				returnValue.errMessage = t("errors.insert_cover_image");
			} else if (error.message === "Inserisci un username") {
				returnValue.errMessage = t("errors.insert_username");
			} else if (error.message === "Inserisci una descrizione") {
				returnValue.errMessage = t("errors.insert_description");
			} else returnValue.errMessage = t("errors.weird_contact_us");
			returnValue.error = true;
		}
		return returnValue;
	}

	async function handleSubmit() {
		setIsLoading(true);
		try {
			const result = await handleUpdateInfoInStorage();
			if (!result.error) {
				await handleUpdateInfoInSC(username, descritpion, result.profile, result.cover);

				openSnackbar(t("common.success"), t("modifyProfile.modify_successfull"), "info");
			} else {
				openSnackbar(t("common.error"), result.errMessage, "error");
			}
		} catch (err) {
			const error = err as Error;
			if (error.message === "Error updating the information in the Smart Contract")
				openSnackbar(t("common.error"), t("errors.updating_information_in_smart_contract"), "error");
			openSnackbar(t("common.error"), t("errors.weird_contact_us"), "error");
		}
		setIsLoading(false);
	}
	return (
		<>
			<ScrollView style={{ backgroundColor: "#151721", flex: 1 }} contentContainerStyle={{ marginHorizontal: 16, rowGap: 16 }}>
				<View style={styles.field_container}>
					<View style={{ flexDirection: "row", alignItems: "center", gap: 8 }}>
						{/* <SvgXml stroke={username != null && username.length > 0 ? "#DB7BC6" : "white"} xml={edit_icon} /> */}
						<Svg
							width={24}
							height={24}
							viewBox="0 0 24 24"
							fill="none"
							stroke={username != null && username.length > 0 ? "#DB7BC6" : "white"}
						>
							<Path
								d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<Path
								d="M18.5 2.50001C18.8978 2.10219 19.4374 1.87869 20 1.87869C20.5626 1.87869 21.1022 2.10219 21.5 2.50001C21.8978 2.89784 22.1213 3.4374 22.1213 4.00001C22.1213 4.56262 21.8978 5.10219 21.5 5.50001L12 15L8 16L9 12L18.5 2.50001Z"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</Svg>
						<Text style={{ color: "white", fontFamily: "Gilroy-Bold", fontSize: 20 }}>Username</Text>
					</View>
					<View style={styles.input_container}>
						<TextInput
							style={styles.username_input}
							onChangeText={(text: string) => {
								setUsername(text.trim());
							}}
							value={username}
							placeholder="Username"
							textAlignVertical="top"
							placeholderTextColor="#aaa"
						/>
					</View>
				</View>

				<View style={styles.field_container}>
					<View style={{ flexDirection: "row", alignItems: "center", gap: 8 }}>
						<Svg
							width={24}
							height={24}
							viewBox="0 0 24 24"
							fill="none"
							stroke={descritpion != null && descritpion.length > 0 ? "#DB7BC6" : "white"}
						>
							<Path
								d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<Path
								d="M18.5 2.50001C18.8978 2.10219 19.4374 1.87869 20 1.87869C20.5626 1.87869 21.1022 2.10219 21.5 2.50001C21.8978 2.89784 22.1213 3.4374 22.1213 4.00001C22.1213 4.56262 21.8978 5.10219 21.5 5.50001L12 15L8 16L9 12L18.5 2.50001Z"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</Svg>
						<Text style={{ color: "white", fontFamily: "Gilroy-Bold", fontSize: 20 }}>{t("common.description")}</Text>
					</View>
					<View style={styles.input_container}>
						<TextInput
							multiline={true}
							numberOfLines={4}
							style={styles.description_input}
							onChangeText={setDescription}
							value={descritpion}
							placeholder="Description"
							placeholderTextColor="#aaa"
							textAlignVertical="top"
						/>
					</View>
				</View>
				<Text style={{ color: "white", fontSize: 20 }}>Socials :</Text>
				<View style={styles.field_container}>
					<View style={[styles.input_container, styles.social_container]}>
						<Feather name="twitter" size={24} color={twitter != null && twitter.length > 0 ? "#DB7BC6" : "white"} />
						<TextInput
							style={styles.username_input}
							onChangeText={setTwitter}
							value={twitter}
							placeholder="Twitter"
							textAlignVertical="center"
							placeholderTextColor="#aaa"
						/>
					</View>
				</View>
				<View style={styles.field_container}>
					<View style={[styles.input_container, styles.social_container]}>
						<Entypo name="instagram" size={24} color={instagram != null && instagram.length > 0 ? "#DB7BC6" : "white"} />
						<TextInput
							style={styles.username_input}
							onChangeText={setInstagram}
							value={instagram}
							placeholder="Instagram"
							textAlignVertical="center"
							placeholderTextColor="#aaa"
						/>
					</View>
				</View>
				<View style={styles.field_container}>
					<View style={[styles.input_container, styles.social_container]}>
						<FontAwesome5 name="telegram-plane" size={24} color={telegram != null && telegram.length > 0 ? "#DB7BC6" : "white"} />
						<TextInput
							style={styles.username_input}
							onChangeText={setTelegram}
							value={telegram}
							placeholder="Telegram"
							textAlignVertical="center"
							placeholderTextColor="#aaa"
						/>
					</View>
				</View>
				<View style={styles.field_container}>
					<View style={[styles.input_container, styles.social_container]}>
						<Feather name="youtube" size={24} color={youtube != null && youtube.length > 0 ? "#DB7BC6" : "white"} />
						<TextInput
							style={styles.username_input}
							onChangeText={setyoutube}
							value={youtube}
							placeholder="Youtube"
							textAlignVertical="center"
							placeholderTextColor="#aaa"
						/>
					</View>
				</View>

				<View style={{ gap: 8, marginTop: 8 }}>
					<View style={{ flexDirection: "row", gap: 8, alignItems: "center" }}>
						<Feather
							name="camera"
							size={24}
							color={profileBackgroundImage.assets != null && profileImage.assets != null ? "#DB7BC6" : "white"}
						/>

						<Text style={{ color: "white", fontFamily: "Gilroy-Bold", fontSize: 20 }}>{t("modifyProfile.upload")}</Text>
					</View>
					<Switch
						// containerStyle={{ flex: 1 }}
						value={isEnabled}
						onValueChange={toggleSwitch}
						disabled={false}
						activeText={t("modifyProfile.cover_image")}
						inActiveText={t("modifyProfile.profile_image")}
						circleSize={48}
						activeTextStyle={{ width: "50%", flex: 1, textAlign: "center", fontFamily: "Gilroy-Bold" }}
						inactiveTextStyle={{ width: "50%", flex: 1, textAlign: "center", fontFamily: "Gilroy-Bold" }}
						circleBorderWidth={4}
						circleBorderActiveColor="#232839"
						circleBorderInactiveColor="#232839"
						backgroundActive={"#232839"}
						backgroundInactive={"#232839"}
						circleActiveColor={"#DB7BC6"}
						circleInActiveColor={"#DB7BC6"}
						renderInsideCircle={() => (
							<>
								{isEnabled ? (
									<Text style={{ color: "white", fontFamily: "Gilroy-Bold" }}>{t("modifyProfile.profile_image")}</Text>
								) : (
									<Text style={{ color: "white", fontFamily: "Gilroy-Bold" }}>{t("modifyProfile.cover_image")}</Text>
								)}
							</>
						)}
						innerCircleStyle={{ alignItems: "center", justifyContent: "center", borderRadius: 24, width: "50%", flex: 1 }}
						outerCircleStyle={{ alignItems: "center", justifyContent: "center", width: "100%", flex: 1 }}
						switchLeftPx={200}
						switchRightPx={200}
						switchWidthMultiplier={(Dimensions.get("window").width - 32) / 48}
						switchBorderRadius={24}
					/>
					{isEnabled ? (
						<>
							<View style={{ display: "flex", flexDirection: "row", gap: 8, flex: 1 }}>
								<View style={[styles.paid_content, { padding: profileBackgroundImage.assets == null ? 48 : 0 }]}>
									<View style={{ display: "flex", rowGap: 16, width: "100%", alignItems: "center", justifyContent: "center" }}>
										<Pressable
											style={styles.upload_button_container}
											onPress={() => {
												pickContent(setProfileBackgroundImage);
											}}
										>
											{profileBackgroundImage.assets == null ? (
												<>
													{/* <SvgXml xml={upload_icon} /> */}
													<Svg width={41} height={41} viewBox="0 0 41 41" fill="none">
														<Path
															d="M13.8335 28.8333L20.5002 35.5L27.1668 28.8333"
															stroke="white"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<Path
															d="M20.5 20.5V35.5"
															stroke="white"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<Path
															d="M35.3001 30.65C36.7491 29.6311 37.8357 28.1769 38.4023 26.4986C38.9688 24.8202 38.9858 23.005 38.4508 21.3164C37.9158 19.6277 36.8566 18.1535 35.4269 17.1076C33.9973 16.0617 32.2715 15.4986 30.5001 15.5H28.4001C27.8989 13.5465 26.9609 11.7321 25.657 10.1935C24.353 8.65487 22.717 7.43211 20.8721 6.61727C19.0273 5.80242 17.0215 5.41672 15.006 5.4892C12.9905 5.56169 11.0177 6.09046 9.23605 7.03572C7.45445 7.98098 5.91051 9.31809 4.72045 10.9464C3.53038 12.5747 2.72522 14.4518 2.36557 16.4363C2.00592 18.4208 2.10115 20.461 2.6441 22.4034C3.18705 24.3458 4.16356 26.1396 5.50013 27.65"
															stroke="white"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</Svg>
													<Text style={{ color: "white" }}>{t("form.choose_photo")}</Text>
												</>
											) : (
												<Image
													source={{
														uri: profileBackgroundImage.assets != null ? profileBackgroundImage.assets[0].uri : "",
													}}
													style={{ width: "100%", height: undefined, aspectRatio: 1, borderRadius: 16 }}
													resizeMode="cover"
												/>
											)}
										</Pressable>
									</View>
								</View>
							</View>
						</>
					) : (
						<>
							<View style={{ display: "flex", flexDirection: "row", gap: 8, flex: 1 }}>
								<View style={[styles.free_content, { padding: profileImage.assets == null ? 48 : 0 }]}>
									<View style={{ display: "flex", rowGap: 16, width: "100%", alignItems: "center", justifyContent: "center" }}>
										<Pressable
											style={styles.upload_button_container}
											onPress={() => {
												pickContent(setProfileImage);
											}}
										>
											{profileImage.assets == null ? (
												<>
													{/* <SvgXml xml={upload_icon} /> */}
													<Svg width={41} height={41} viewBox="0 0 41 41" fill="none">
														<Path
															d="M13.8335 28.8333L20.5002 35.5L27.1668 28.8333"
															stroke="white"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<Path
															d="M20.5 20.5V35.5"
															stroke="white"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
														<Path
															d="M35.3001 30.65C36.7491 29.6311 37.8357 28.1769 38.4023 26.4986C38.9688 24.8202 38.9858 23.005 38.4508 21.3164C37.9158 19.6277 36.8566 18.1535 35.4269 17.1076C33.9973 16.0617 32.2715 15.4986 30.5001 15.5H28.4001C27.8989 13.5465 26.9609 11.7321 25.657 10.1935C24.353 8.65487 22.717 7.43211 20.8721 6.61727C19.0273 5.80242 17.0215 5.41672 15.006 5.4892C12.9905 5.56169 11.0177 6.09046 9.23605 7.03572C7.45445 7.98098 5.91051 9.31809 4.72045 10.9464C3.53038 12.5747 2.72522 14.4518 2.36557 16.4363C2.00592 18.4208 2.10115 20.461 2.6441 22.4034C3.18705 24.3458 4.16356 26.1396 5.50013 27.65"
															stroke="white"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
														/>
													</Svg>
													<Text style={{ color: "white" }}>{t("form.choose_photo")}</Text>
												</>
											) : (
												<Image
													source={{ uri: profileImage.assets != null ? profileImage.assets[0].uri : "" }}
													style={{ width: "100%", height: undefined, aspectRatio: 1, borderRadius: 16 }}
													resizeMode="cover"
												/>
											)}
										</Pressable>
									</View>
								</View>
							</View>
						</>
					)}
				</View>
			</ScrollView>
			<View style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: 16, backgroundColor: "#151721" }}>
				<Pressable onPress={handleSubmit} style={[styles.post_button, { backgroundColor: "#232839" }]}>
					<Feather name="camera" size={24} color="white" />
					<Text style={{ color: "white" }}>{t("modifyProfile.modify_profile")}</Text>
				</Pressable>
			</View>
		</>
	);
}
const styles = StyleSheet.create({
	post_button: {
		paddingVertical: 12,
		width: "80%",
		backgroundColor: "#5F5F5F",
		flexDirection: "row",
		gap: 8,
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 16,
	},
	input_container: {
		backgroundColor: "transparent",
		width: "100%",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		borderRadius: 8,
	},
	social_container: {
		// marginBottom: 12,
		gap: 8,
	},
	description_input: {
		color: "white",
		backgroundColor: "transparent",
		flex: 1,
		minHeight: Platform.OS === "ios" ? 50 : undefined,
	},
	username_input: {
		color: "white",
		backgroundColor: "transparent",
		flex: 1,
	},
	button: {
		minWidth: "50%",
		padding: 12,
		paddingVertical: 24,
		backgroundColor: "#DB7BC6",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 16,
	},
	field_container: {
		gap: 8,
		padding: 16,
		borderRadius: 16,
		backgroundColor: "#232839",
	},
	upload_button_container: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		rowGap: 8,
		alignSelf: "stretch",
	},
	free_content: {
		width: "100%",
		aspectRatio: 1,
		backgroundColor: "#232839",
		borderRadius: 16,
		padding: 48,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	paid_content: {
		width: "100%",
		aspectRatio: 1,
		backgroundColor: "#232839",
		borderRadius: 16,
		padding: 48,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
});
