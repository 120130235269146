import { StyleSheet, View, Pressable, Text, Image } from "react-native";
import logo from "../../assets/logo.png";
import { AntDesign } from "@expo/vector-icons";
import { useTranslation } from "react-i18next";

export default function WalletInitialization({ navigation }: { navigation: any }) {
	const { t } = useTranslation();
	return (
		<>
			<View style={styles.wallet_initialization_container}>
				<View style={{ width: "80%", flexDirection: "row", marginTop: 48 }}>
					<Image source={logo} style={{ width: "100%", aspectRatio: 1398 / 306 }} />
				</View>
				<View style={{ gap: 16, width: "100%", justifyContent: "center", alignItems: "center", flex: 1 }}>
					<Pressable
						onPress={() => {
							navigation.navigate("Quick Access");
						}}
						style={[styles.button_style, { backgroundColor: "#DB7BC6" }]}
					>
						<View style={styles.button_text_container}>
							<Text style={styles.button_text_style}>{t("pages.quick_access")}</Text>
						</View>
						<AntDesign name="right" size={16} color="white" />
					</Pressable>
					<Pressable
						onPress={() => {
							navigation.navigate("Import Wallet");
						}}
						style={styles.button_style}
					>
						<View style={styles.button_text_container}>
							<Text style={styles.button_text_style}>{t("pages.import_wallet")}</Text>
						</View>
						<AntDesign name="right" size={16} color="white" />
					</Pressable>
				</View>
			</View>
		</>
	);
}

const styles = StyleSheet.create({
	button_style: {
		backgroundColor: "#232839",
		minWidth: "50%",
		borderRadius: 16,
		padding: 24,
		zIndex: 0,
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	button_text_style: {
		fontSize: 20,
		color: "white",
		fontWeight: "bold",
		fontFamily: "Gilroy-Bold",
	},
	button_text_container: { display: "flex", flexDirection: "row", alignItems: "center", gap: 8, flex: 1, marginRight: 8 },

	wallet_information_container: {
		display: "flex",
		flexDirection: "column",
		rowGap: 1,
		gap: 1,
	},
	wallet_initialization_container: {
		backgroundColor: "#151721",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		rowGap: 16,
		height: "100%",
	},
	wallet_information_text: {
		color: "white",
	},
	mnemonic_container: {
		backgroundColor: "gray",
		padding: 16,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	mnemonic_text: {
		fontSize: 24,
		color: "#151721",
	},
});
