import React from "react";
import { View, StyleSheet, Pressable, ActivityIndicator } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { Feather } from "@expo/vector-icons";
import Slider, { SliderProps } from "@react-native-community/slider";
const VideoControls = ({
	state,
	togglePlay,
	playbackInstanceInfo,
	setPlaybackInstanceInfo,
	playbackInstance,
	toggleFullScreen,
}: {
	state: any;
	togglePlay: any;
	playbackInstanceInfo: any;
	setPlaybackInstanceInfo: any;
	playbackInstance: any;
	toggleFullScreen: () => void;
}) => {
	function renderIcon() {
		if (state === "Buffering") {
			return <ActivityIndicator size="small" color="#fff" />;
		} else if (state === "Playing") {
			return <Feather name="pause" size={24} color="#fff" />;
		} else if (state === "Paused") {
			return <Feather name="play" size={24} color="#fff" />;
		} else if (state === "Ended") {
			return <MaterialIcons name="replay" size={24} color="#fff" />;
		}
	}
	return (
		<View style={styles.container}>
			<View style={styles.innerContainer}>
				<Pressable style={styles.iconWrapper} onPress={state === "Buffering" ? null : togglePlay}>
					{renderIcon()}
				</Pressable>
				<Slider
					style={styles.slider}
					thumbTintColor={"#fff"}
					minimumTrackTintColor={"#DB7BC6"}
					maximumTrackTintColor="#8E9092"
					maximumValue={playbackInstanceInfo.duration}
					value={playbackInstanceInfo.position}
					onSlidingStart={() => {
						if (playbackInstanceInfo.state === "Playing") {
							togglePlay();
							setPlaybackInstanceInfo({ ...playbackInstanceInfo, state: "Paused" });
						}
					}}
					onSlidingComplete={async (e: number) => {
						const position = e;
						if (playbackInstance) {
							await playbackInstance.setStatusAsync({
								positionMillis: position,
								shouldPlay: true,
							});
						}
						setPlaybackInstanceInfo({
							...playbackInstanceInfo,
							position,
						});
					}}
				/>
				<Pressable onPress={toggleFullScreen}>
					<MaterialIcons name="fullscreen" size={24} color="black" />
				</Pressable>
			</View>
		</View>
	);
};
const styles = StyleSheet.create({
	container: {
		flex: 1,
		flexDirection: "row",
		width: "100%",
		paddingHorizontal: 20,
	},
	innerContainer: {
		flexDirection: "row",
		alignItems: "center",
		borderRadius: 50,
		paddingHorizontal: 12,
		paddingRight: 20,
		width: "100%",
		height: 66,
	},
	iconWrapper: {
		backgroundColor: "rgba(0, 0, 0, 0.2)",
		justifyContent: "center",
		alignItems: "center",
		height: 50,
		width: 50,
		borderRadius: 50,
	},
	slider: {
		flex: 1,
		marginHorizontal: 20,
	},
});
export default VideoControls;
