import { Pressable, StyleSheet, Text, TouchableOpacity } from "react-native";
import { CategoryType } from "../../types/content/Contents";
import { useMemo, memo } from "react";
function CategoryButton({
	category,
	categorySelected,
	handleToggleCategory,
}: {
	category: CategoryType;
	categorySelected: boolean;
	handleToggleCategory: (category: CategoryType) => void;
}) {
	function changeBackground(): { backgroundColor: string } | {} {
		if (categorySelected) return { backgroundColor: "#DB7BC6" };
		else return {};
	}
	return (
		<>
			<Pressable
				style={[styles.category_button_container, useMemo(() => changeBackground(), [categorySelected])]}
				onPress={() => {
					handleToggleCategory(category);
				}}
			>
				<Text style={styles.category_text}>{category.name}</Text>
			</Pressable>
		</>
	);
}
const styles = StyleSheet.create({
	category_button_container: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 10,
		padding: 8,
		borderWidth: 4,
		borderColor: "#DB7BC6",
		backgroundColor: "transparent",
	},
	category_text: {
		color: "white",
	},
});

export default memo(CategoryButton);
