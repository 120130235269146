export const isPhoto = (fileUrl: string) => {
	let isImage = true;
	const extension = fileUrl.split(".").pop()?.toLowerCase();
	if (extension == null) {
		throw new Error("Extension not found");
	}
	const imageExtensions = ["jpg", "jpeg", "png", "gif"];
	const videoExtensions = ["mov", "mp4", "avi", "mkv"];
	if (imageExtensions.includes(extension)) {
		isImage = true;
	} else if (videoExtensions.includes(extension)) {
		isImage = false;
	} else {
		throw new Error("Extension not supported");
	}

	return isImage;
};
export function fileTypeToExtension(fileType: string): string | null {
	const fileTypesToExtensions: { [key: string]: string } = {
		"image/jpeg": ".jpg",
		"image/png": ".png",
		"image/gif": ".gif",
		"image/bmp": ".bmp",
		"image/webp": ".webp",
		"video/mp4": ".mp4",
		"video/mpeg": ".mpeg",
		"video/quicktime": ".mov",
		"video/x-msvideo": ".avi",
		"video/x-ms-wmv": ".wmv",
	};

	return fileTypesToExtensions[fileType] || null;
}
