import { StyleSheet, Text, View, Pressable, TouchableOpacity, Platform } from "react-native";
import * as React from "react";
import { useState } from "react";
import * as LocalAuthentication from "expo-local-authentication";
import { useAuthenticationContext } from "../providers/AuthenticationProvider";
import Ripple from "react-native-material-ripple";
import { useEffect } from "react";
import { Entypo } from "@expo/vector-icons";
import Header from "../components/common/Header";
import { FontAwesome } from "@expo/vector-icons";
import { useTranslation } from "react-i18next";

export default function Pin({
	pin,
	setPin,
	setLoggedUsingBiometrics,
	isRegistration,
	resetPin,
	handleRegistration,
}: {
	pin: number[];
	setPin: React.Dispatch<React.SetStateAction<number[]>>;
	setLoggedUsingBiometrics?: React.Dispatch<React.SetStateAction<boolean>>;
	isRegistration: boolean;
	resetPin: boolean;
	handleRegistration?: (pin: number[]) => Promise<void>;
}) {
	const { hasEnabledBiometrics } = useAuthenticationContext();
	const [biometricsEnabled, setBiometricsEnabled] = useState<boolean>(false);
	const [localPin, setLocalPin] = useState<number[]>([]);
	const [isShowing, setIsShowing] = useState<boolean>(false);
	const [pinFilled, setPinFilled] = useState<boolean>(false);
	const { t } = useTranslation();
	const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));
	useEffect(() => {
		(async () => {
			if (handleRegistration != null && isRegistration && pinFilled) {
				await handleRegistration(localPin);
			} else if ((localPin.length === 5 && !isRegistration) || pinFilled) {
				await wait(500);
				setPin(localPin);
			}
		})();
	}, [localPin, pinFilled]);

	useEffect(() => {
		if (resetPin) handleResetPin();
	}, [resetPin]);

	function handleResetPin() {
		setLocalPin([]);
	}

	function handleSetPin(value: number) {
		if (localPin.length <= 4) {
			setLocalPin([...localPin, value]);
		}
	}

	function handleDelete() {
		if (localPin.length > 0) {
			setLocalPin(localPin.slice(0, -1));
		}
	}
	const onFaceId = async () => {
		try {
			const isCompatible = await LocalAuthentication.hasHardwareAsync();

			if (!isCompatible) {
				throw new Error(t("errors.your_device_is_not_compatible"));
			}

			const isEnrolled = await LocalAuthentication.isEnrolledAsync();
			const as = LocalAuthentication.hasHardwareAsync();

			if (!isEnrolled) {
				throw new Error(t("errors.no_face_or_fingers_detected"));
			}
			const res = await LocalAuthentication.authenticateAsync();
			if (res.success && setLoggedUsingBiometrics != null) {
				setLoggedUsingBiometrics(true);
			}
		} catch (err) {
			const error = err as Error;
			console.log(error);
		}
	};
	const handleBiometricsAuth = async () => {
		let enableBiometrics = await hasEnabledBiometrics();
		setBiometricsEnabled(enableBiometrics);
		if (!isRegistration && enableBiometrics) onFaceId();
	};
	React.useEffect(() => {
		handleBiometricsAuth();
	}, []);

	return (
		<View style={styles.pin_page_container}>
			<View style={{ width: "100%", marginBottom: 16 }}>
				<Header />
			</View>
			<View style={styles.pin_container}>
				<Text style={{ fontFamily: "Gilroy-Bold", fontSize: 20, color: "white" }}>{t("pin.enter_pin")}</Text>
				<View style={{ flexDirection: "row", gap: 16 }}>
					{Array.from([1, 2, 3, 4, 5]).map((value, index) => {
						if (localPin[index] == null)
							return (
								<View
									style={{ width: 32, borderWidth: 2, height: 30, borderColor: "transparent", borderBottomColor: "white" }}
								></View>
							);
						if (isShowing) return <Text style={styles.pin_text}>{localPin[index]}</Text>;
						return <FontAwesome name="asterisk" size={32} color="white" />;
					})}
					{isShowing ? (
						<FontAwesome name="eye" size={32} color="white" onPress={() => setIsShowing(false)} />
					) : (
						<FontAwesome name="eye-slash" size={32} color="white" onPress={() => setIsShowing(true)} />
					)}
				</View>
			</View>
			{isRegistration == false || localPin.length < 5 ? (
				<View
					style={{
						flex: 1,
						width: "100%",
						alignItems: "center",
						justifyContent: "center",
						alignContent: "center",
						alignSelf: "stretch",
					}}
				>
					<View
						style={{
							width: "100%",
							height: "100%",
							justifyContent: "flex-end",
							maxWidth: 600,
							alignItems: "center",
							alignSelf: "center",
						}}
					>
						<View style={styles.pin_button_container}>
							<View style={styles.pin_row}>
								<TouchableOpacity activeOpacity={0.7} style={styles.pin_button} onPress={() => handleSetPin(1)}>
									<Text style={styles.pin_button_text}>1</Text>
								</TouchableOpacity>
								<TouchableOpacity activeOpacity={0.7} style={styles.pin_button} onPress={() => handleSetPin(2)}>
									<Text style={styles.pin_button_text}>2</Text>
								</TouchableOpacity>
								<TouchableOpacity activeOpacity={0.7} style={styles.pin_button} onPress={() => handleSetPin(3)}>
									<Text style={styles.pin_button_text}>3</Text>
								</TouchableOpacity>
							</View>
							<View style={styles.pin_row}>
								<TouchableOpacity activeOpacity={0.7} style={styles.pin_button} onPress={() => handleSetPin(4)}>
									<Text style={styles.pin_button_text}>4</Text>
								</TouchableOpacity>
								<TouchableOpacity activeOpacity={0.7} style={styles.pin_button} onPress={() => handleSetPin(5)}>
									<Text style={styles.pin_button_text}>5</Text>
								</TouchableOpacity>
								<TouchableOpacity activeOpacity={0.7} style={styles.pin_button} onPress={() => handleSetPin(6)}>
									<Text style={styles.pin_button_text}>6</Text>
								</TouchableOpacity>
							</View>
							<View style={styles.pin_row}>
								<TouchableOpacity activeOpacity={0.7} style={styles.pin_button} onPress={() => handleSetPin(7)}>
									<Text style={styles.pin_button_text}>7</Text>
								</TouchableOpacity>
								<TouchableOpacity activeOpacity={0.7} style={styles.pin_button} onPress={() => handleSetPin(8)}>
									<Text style={styles.pin_button_text}>8</Text>
								</TouchableOpacity>
								<TouchableOpacity activeOpacity={0.7} style={styles.pin_button} onPress={() => handleSetPin(9)}>
									<Text style={styles.pin_button_text}>9</Text>
								</TouchableOpacity>
							</View>
							<View style={styles.pin_row}>
								<View style={styles.pin_button}>
									{biometricsEnabled && (
										<Pressable onPress={() => onFaceId()}>
											<Entypo name="fingerprint" size={32} color="black" />
										</Pressable>
									)}
								</View>
								<TouchableOpacity activeOpacity={0.7} style={styles.pin_button} onPress={() => handleSetPin(0)}>
									<Text style={styles.pin_button_text}>0</Text>
								</TouchableOpacity>
								<TouchableOpacity activeOpacity={0.7} style={styles.pin_button} onPress={() => handleDelete()}>
									<Text style={styles.pin_button_text}>Del</Text>
								</TouchableOpacity>
							</View>
						</View>
					</View>
				</View>
			) : (
				<>
					<View
						style={{
							flex: 1,
							justifyContent: "space-between",
							width: "100%",
							flexDirection: "row",
							maxWidth: 600,
							alignItems: "flex-end",
							marginBottom: 64,
							paddingHorizontal: 16,
						}}
					>
						<Pressable
							style={{ backgroundColor: "#232839", padding: 16, paddingHorizontal: 32, borderRadius: 16 }}
							onPress={() => {
								setPinFilled(false);
								handleResetPin();
							}}
						>
							<Text style={{ fontFamily: "Gilroy-Bold", fontSize: 20, color: "white" }}>{t("pin.reinsert_pin")}</Text>
						</Pressable>
						<Pressable
							style={{ backgroundColor: "#DB7BC6", padding: 16, paddingHorizontal: 32, borderRadius: 16 }}
							onPress={() => {
								setPinFilled(true);
							}}
						>
							<Text style={{ fontFamily: "Gilroy-Bold", fontSize: 20, color: "white" }}>{t("pin.continue")}</Text>
						</Pressable>
					</View>
				</>
			)}
		</View>
	);
}

const styles = StyleSheet.create({
	pin_page_container: {
		display: "flex",
		height: "100%",
		backgroundColor: "#151721",
		justifyContent: "flex-end",
		alignItems: "center",
	},
	pin_container: {
		flexBasis: "20%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		rowGap: 8,
	},
	pin_text: {
		width: 32,
		fontSize: 32,
		fontFamily: "Gilroy-Bold",
		color: "white",
	},
	pin_button_container: {
		flex: 1,
		position: "relative",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
		alignSelf: "stretch",
		marginHorizontal: 10,
		rowGap: 16,
		marginBottom: 32,
	},

	pin_button: {
		maxWidth: 150,
		width: "22%",
		height: undefined,
		aspectRatio: 1,
		backgroundColor: "#232839",
		borderRadius: 100,
		display: "flex",
		alignSelf: "flex-end",
		justifyContent: "center",
		alignItems: "center",
		color: "white",
		margin: 0.5,
	},
	pin_row: {
		flexDirection: "row",
		justifyContent: "space-evenly",
		alignItems: "center",
		width: "100%",
	},
	pin_button_text: {
		color: "white",
		fontFamily: "Gilroy-Bold",
		fontSize: 20,
	},
});
