import { StyleSheet, FlatList, Text, View, Platform, TouchableOpacity } from "react-native";

import Content from "../components/common/Content";
import LoadingIndicator from "../components/common/LoadingIndicator";

import { useContentContext } from "../providers/ContentProvider";
import { SafeAreaView } from "react-native-safe-area-context";
import Header from "../components/common/Header";
import { useEffect, useState, useRef } from "react";
import { RefreshControl } from "react-native-gesture-handler";
import LoadingComponent from "../components/loading/LoadingComponent";
import { patchFlatListProps } from "react-native-web-refresh-control";
import { G, Path, Svg } from "react-native-svg";
if (Platform.OS === "web") patchFlatListProps({ tintColor: "#DB7BC6" });
export default function MyPurchase({ navigation }: { navigation: any }) {
	const {
		feedContent,
		incrementFeedContentDisplayed,
		isLoadingFeedContent,
		purchaseFeedContent,
		incrementPurchasedFeedContentDisplayed,
		isLoadingPurchasedFeedContent,
		refreshPaidContent,
	} = useContentContext();

	const [refreshing, setRefresh] = useState<boolean>(false);
	async function handleReload() {
		setRefresh(true);
		await refreshPaidContent();
		elContentLenghtRef.current.clear();
		elImageLenghtRef.current.clear();
		elAspectRatioRef.current.clear();
		setRefresh(false);
	}
	function handleLoadMore() {
		incrementPurchasedFeedContentDisplayed();
	}
	const elContentLenghtRef = useRef<Map<number, number>>(new Map<number, number>());
	const elImageLenghtRef = useRef<Map<number, number>>(new Map<number, number>());
	const elAspectRatioRef = useRef<Map<number, number>>(new Map<number, number>());

	return (
		<>
			<SafeAreaView style={{ backgroundColor: "#151721", flex: 1 }}>
				{purchaseFeedContent.length !== 0 ? (
					<FlatList
						ListHeaderComponent={
							<>
								<View style={{ flex: 1, height: "100%", minWidth: "40%" }}>
									<Header />
								</View>
								<TouchableOpacity style={[styles.accordHeader, { opacity: 0 }]} activeOpacity={0}>
									<Svg width={24} height={24} viewBox="0 0 24 24" stroke="white" fill="none">
										<G id="grid">
											<Path d="M10 3H3V10H10V3Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
											<Path d="M21 3H14V10H21V3Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
											<Path d="M21 14H14V21H21V14Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
											<Path d="M10 14H3V21H10V14Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
										</G>
									</Svg>
								</TouchableOpacity>
							</>
						}
						ListHeaderComponentStyle={{
							paddingHorizontal: 8,
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							backgroundColor: "#151721",
							marginBottom: 32,
						}}
						stickyHeaderIndices={[0]}
						stickyHeaderHiddenOnScroll={true}
						style={styles.feed}
						data={purchaseFeedContent}
						onEndReached={handleLoadMore}
						onRefresh={Platform.OS === "web" ? handleReload : undefined}
						refreshing={Platform.OS === "web" ? refreshing : undefined}
						refreshControl={
							<>{Platform.OS !== "web" && <RefreshControl refreshing={refreshing} onRefresh={handleReload} tintColor={"#DB7BC6"} />}</>
						}
						ItemSeparatorComponent={() => <View style={{ height: 32 }}></View>}
						renderItem={({ item, index }) => {
							return (
								<Content
									uri={item.content.paidContent}
									isLocked={false}
									content={item.content}
									creator={item.creator}
									navigation={navigation}
									setContentHeight={(height) => {
										if (height > 0) {
											elContentLenghtRef.current.set(index, height);
										}
									}}
									setImageHeight={(height) => {
										if (height > 0) {
											elImageLenghtRef.current.set(index, height);
										}
									}}
									setAspectRatioRef={(ratio) => {
										if (ratio > 0) {
											elAspectRatioRef.current.set(index, ratio);
										}
									}}
									ratio={elAspectRatioRef.current.get(index)}
									imageHeight={elImageLenghtRef.current.get(index)}
									contentHeight={elContentLenghtRef.current.get(index)}
								/>
							);
						}}
						initialNumToRender={0}
						maxToRenderPerBatch={4}
						updateCellsBatchingPeriod={1}
						windowSize={2}
						viewabilityConfig={{ waitForInteraction: false }}
						keyExtractor={(item) => item.content.id.toString()}
						onEndReachedThreshold={0.4}
						ListFooterComponent={<LoadingIndicator isLoading={isLoadingPurchasedFeedContent} />}
					/>
				) : (
					<>
						<View
							style={{
								paddingHorizontal: 8,
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "center",
								backgroundColor: "#151721",
								marginBottom: 32,
							}}
						>
							<View style={{ flex: 1, height: "100%", minWidth: "40%" }}>
								<Header />
							</View>
							<TouchableOpacity style={[styles.accordHeader, { opacity: 0 }]} activeOpacity={0}>
								<Svg width={24} height={24} viewBox="0 0 24 24" stroke="white" fill="none">
									<G id="grid">
										<Path d="M10 3H3V10H10V3Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
										<Path d="M21 3H14V10H21V3Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
										<Path d="M21 14H14V21H21V14Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
										<Path d="M10 14H3V21H10V14Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
									</G>
								</Svg>
							</TouchableOpacity>
						</View>
						{isLoadingPurchasedFeedContent ? (
							<LoadingIndicator isLoading={isLoadingPurchasedFeedContent} />
						) : (
							<FlatList
								data={[0]}
								onRefresh={Platform.OS === "web" ? handleReload : undefined}
								refreshing={Platform.OS === "web" ? refreshing : undefined}
								refreshControl={
									<>
										{Platform.OS !== "web" && (
											<RefreshControl refreshing={refreshing} onRefresh={handleReload} tintColor={"#DB7BC6"} />
										)}
									</>
								}
								renderItem={({ item, index }) => {
									return (
										<View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
											<Text style={{ color: "white", fontSize: 20, textAlign: "center", marginTop: 20 }}>No purchases</Text>
										</View>
									);
								}}
							/>
						)}
					</>
				)}
			</SafeAreaView>
		</>
	);
}

const styles = StyleSheet.create({
	feed: {
		backgroundColor: "#151721",
	},
	accordHeader: {
		// marginRight: 8,
		padding: 8,
		backgroundColor: "transparent",
		color: "#DB7BC6",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		borderRadius: 32,
		// borderWidth: 2,
		// borderColor: "#DB7BC6",
		alignSelf: "center",
	},
});
