import { ScrollView, StyleSheet, Text, TouchableOpacity, Pressable, View } from "react-native";
import { Feather } from "@expo/vector-icons";
import * as Clipboard from "expo-clipboard";

import { useWalletContext } from "../../providers/WalletProvider";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "../../providers/SnackBarProvider";
export default function Receive() {
	const { walletInfo } = useWalletContext();
	const { t } = useTranslation();
	const { openSnackbar } = useSnackbar();
	async function copyToClipboard(wallet_info: string | undefined) {
		if (wallet_info == null) return;
		try {
			await Clipboard.setStringAsync(wallet_info);
			openSnackbar("Success", t("messanges.saved_in_clipboard"), "success");
		} catch (e) {
			openSnackbar("Error", t("errors.saved_in_clipboard"), "error");
			console.log(e);
		}
	}
	return (
		<>
			<View style={styles.receive_page_container}>
				<ScrollView style={{ width: "100%" }}>
					<View style={styles.wallet_information_container}>
						<View style={styles.wallet_field_container}>
							<Pressable
								onPress={async () => {
									await copyToClipboard(walletInfo?.address);
								}}
								style={{
									flexDirection: "row",
									gap: 8,
									alignItems: "center",
									borderRadius: 32,
									backgroundColor: "rgba(219, 123, 198, 0.4)",
									paddingHorizontal: 16,
									paddingVertical: 8,
									alignSelf: "flex-start",
								}}
							>
								<Feather name="copy" size={20} color="white" />
								<Text style={[styles.wallet_information_text, { fontSize: 16, fontFamily: "Gilroy-Bold" }]}>Address</Text>
							</Pressable>
							<Text style={styles.wallet_information_text}>{walletInfo?.address}</Text>
						</View>
					</View>
				</ScrollView>
			</View>
		</>
	);
}
const styles = StyleSheet.create({
	receive_page_container: {
		padding: 16,
		flex: 1,
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "#151721",
	},
	wallet_information_container: {
		display: "flex",
		flexDirection: "column",
		rowGap: 16,
	},
	wallet_field_container: {
		backgroundColor: "#232839",
		padding: 16,
		borderRadius: 16,
		rowGap: 8,
	},
	wallet_information_text: {
		color: "white",
	},
});
