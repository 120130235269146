import { View, Image, StyleSheet, Text, Dimensions, Pressable, ImageBackground } from "react-native";
import { CreatorType } from "../../types/creator/Creator";
import HeaderContent from "./HeaderContent";
import { useEffect, useState, memo, useRef } from "react";
import { ContentType } from "../../types/content/Contents";
import { useImageContext } from "../../providers/ImageProvider";
import ImageViewer from "./ImageViewer";
import ShimmerContent from "../loading/ShimmerContent";
import VideoViewer from "./VideoViewer";
import { Ionicons } from "@expo/vector-icons";
import { useContentContext } from "../../providers/ContentProvider";
import { LinearGradient } from "expo-linear-gradient";
import { Feather } from "@expo/vector-icons";
import { event } from "react-native-reanimated";
import { useBlockchainContext } from "../../providers/BlockchainProvider";
import { useSnackbar } from "../../providers/SnackBarProvider";
import PurchaseContentModal from "../modal/PurchaseContentModal";
import { useTranslation } from "react-i18next";

function Content({
	isLocked,
	content,
	uri,
	creator,
	navigation,
	contentHeight,
	imageHeight,
	setHeight,
	setContentHeight,
	setImageHeight,
	setAspectRatioRef,
	ratio,
}: {
	uri: string;
	isLocked: boolean;
	content: ContentType;
	creator: CreatorType;
	navigation: any;
	contentHeight?: number;
	imageHeight?: number;
	setHeight?: (height: number) => void;
	setContentHeight?: (height: number) => void;
	setImageHeight?: (height: number) => void;
	setAspectRatioRef?: (ratio: number) => void;
	ratio?: number;
}) {
	const { getImage } = useImageContext();
	const { purchaseContent } = useContentContext();
	const [imageUrl, setImageUrl] = useState<string>("");
	const [previewImageUri, setPreviewImageUri] = useState<string>("");
	const [fullImage, setFullImage] = useState<boolean>(false);
	const [ImageAspectRatio, setAspectRatio] = useState<number>(0.75);
	const [contentHeightCurrent, setContentHeightCurrent] = useState<number | undefined>(50);
	const [showPurchaseModal, setShowPurchaseModal] = useState<boolean>(false);

	const { t } = useTranslation();
	useEffect(() => {
		const getImageSize = async () => {
			let imageURL = imageUrl;
			if (content.type === "video" && !isLocked) imageURL = previewImageUri;
			await Image.getSize(imageURL, (width, height) => {
				if (ImageAspectRatio < width / height) {
					setAspectRatio(width / height);
					setAspectRatioRef && setAspectRatioRef(width / height);
				} else {
					setAspectRatioRef && setAspectRatioRef(ImageAspectRatio);
				}
			});
		};

		if (ratio != null && ratio != 0) {
			setAspectRatio(ratio);
		} else if (
			(isLocked && imageUrl != "") ||
			(imageUrl != "" && content.type !== "video") ||
			(previewImageUri != "" && content.type === "video")
		) {
			getImageSize();
		}
	}, [imageUrl, previewImageUri]);
	useEffect(() => {
		getImageUrl();
		if (content.type === "video" && !isLocked) {
			(async () => {
				const CachedImage = await getImage("https://app.netlyfans.com/uploads/", content.previewImage);
				setPreviewImageUri(CachedImage.cacheUri);
			})();
		}
		if (contentHeight != null && contentHeight != 0) {
			setContentHeightCurrent(contentHeight);
		}
	}, []);

	function goToPurchasePage() {
		setShowPurchaseModal(true);
	}
	function goToMyPurchase() {
		navigation.navigate("MyPurchase");
	}

	async function getImageUrl() {
		try {
			if (content.type === "image" || isLocked) {
				const CachedImage = await getImage("https://app.netlyfans.com/uploads/", uri);
				setImageUrl(CachedImage.cacheUri);
			} else {
				setImageUrl("https://app.netlyfans.com/uploads/" + uri);
			}
		} catch (err) {
			console.log(err);
		}
	}
	function handleCloseFullImage() {
		setFullImage(false);
	}
	function handleClosePurchaseModal() {
		setShowPurchaseModal(false);
	}
	return (
		<>
			<View style={styles.container} onLayout={(event) => setHeight && setHeight(event.nativeEvent.layout.height)}>
				{imageUrl === "" || (content.type === "video" && !isLocked && previewImageUri === "") || false ? (
					<>
						<View style={{ rowGap: 8 }}>
							<View style={{ width: 40, height: 40, borderRadius: 30, overflow: "hidden" }}>
								<ShimmerContent width="100%" height="100%" />
							</View>
							<View style={{ borderRadius: 16, overflow: "hidden" }}>
								<ShimmerContent
									width="100%"
									height={imageHeight != null ? imageHeight : (Dimensions.get("window").width - 16) / ImageAspectRatio}
								/>
							</View>
							<View style={{ borderRadius: 16, overflow: "hidden", marginTop: 8 }}>
								<ShimmerContent width="100%" height={contentHeightCurrent != null ? contentHeightCurrent : 50} />
							</View>
						</View>
					</>
				) : (
					<>
						<HeaderContent creator={creator} navigation={navigation} />

						<View style={styles.image_container}>
							{!isLocked &&
								(content.type === "image" ? (
									<>
										<ImageViewer handleBack={handleCloseFullImage} fullImage={fullImage} uri={imageUrl} />
										<Pressable onPress={() => setFullImage(true)} style={{ width: "100%" }}>
											<View pointerEvents="none">
												<Image
													style={[styles.image, { aspectRatio: ImageAspectRatio }]}
													source={{ uri: imageUrl }}
													onError={(err) => {
														console.log(err);
														console.log(err.nativeEvent);
													}}
													onLayout={(event) => setImageHeight && setImageHeight(event.nativeEvent.layout.height)}
												/>
											</View>
										</Pressable>
										<View
											style={{
												marginTop: 16,
												alignItems: "flex-start",
												justifyContent: "flex-start",
												width: "100%",
												paddingHorizontal: 8,
											}}
											onLayout={(event) => {
												setContentHeight && setContentHeight(event.nativeEvent.layout.height);
											}}
										>
											<Text style={{ color: "white", textAlign: "left", width: "100%" }}>{content.title}</Text>
										</View>
									</>
								) : (
									<>
										<VideoViewer handleBack={handleCloseFullImage} visible={fullImage} uri={imageUrl} />
										<Pressable
											onPress={() => setFullImage(true)}
											style={{ width: "100%", position: "relative", justifyContent: "center", alignItems: "center" }}
										>
											<View pointerEvents="none" style={{ flex: 1, height: "100%", width: "100%" }}>
												<Image
													style={[styles.image, { opacity: 0.7, aspectRatio: ImageAspectRatio }]}
													source={{ uri: previewImageUri }}
													onError={(err) => {
														console.log(err);
														console.log(err.nativeEvent);
													}}
													onLayout={(event) => setImageHeight && setImageHeight(event.nativeEvent.layout.height)}
												/>
											</View>
											<Ionicons name="play-outline" size={96} color="#DB7BC6" style={{ position: "absolute" }} />
										</Pressable>
										<View
											style={{
												marginTop: 16,
												alignItems: "flex-start",
												justifyContent: "flex-start",
												width: "100%",
												paddingHorizontal: 8,
											}}
											onLayout={(event) => {
												setContentHeight && setContentHeight(event.nativeEvent.layout.height);
											}}
										>
											<Text style={{ color: "white", textAlign: "left", width: "100%" }}>{content.title}</Text>
										</View>
									</>
								))}

							{isLocked && (
								<>
									<PurchaseContentModal
										id={content.id.toString()}
										matic={content.matic}
										handleBack={handleClosePurchaseModal}
										isOpen={showPurchaseModal}
										navigation={navigation}
									/>
									<View style={{ position: "relative", flex: 1, width: "100%" }}>
										<View pointerEvents="none">
											<Image
												style={[styles.image, { aspectRatio: ImageAspectRatio }]}
												source={{
													uri: imageUrl,
												}}
												onLayout={(event) => setImageHeight && setImageHeight(event.nativeEvent.layout.height)}
												fadeDuration={200}
											/>
										</View>
										{!purchaseContent.includes(content.id) && (
											<LinearGradient
												colors={["rgba(171, 49, 145, 0.90)", "rgba(219, 123, 198, 0.90)", " rgba(167, 42, 139, 0.90)"]}
												start={{ x: 0, y: 0 }}
												end={{ x: 1, y: 0 }}
												style={{
													position: "absolute",
													top: 16,
													left: 16,
													padding: 8,
													minWidth: "20%",
													borderRadius: 8,
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<Text style={{ color: "white", fontFamily: "Gilroy-Bold", fontSize: 12 }}>
													{content.matic.toFixed(1)} Matic
												</Text>
											</LinearGradient>
										)}
										<View
											onLayout={(event) => {
												setContentHeight && setContentHeight(event.nativeEvent.layout.height);
											}}
											style={{ marginTop: 16, gap: 16, paddingHorizontal: 16, justifyContent: "flex-start", width: "100%" }}
										>
											<Text style={{ color: "white", textAlign: "left" }}>{content.title}</Text>

											{purchaseContent.includes(content.id) ? (
												<>
													<Pressable style={styles.bought_overlay} onPress={goToMyPurchase}>
														<Text style={styles.bought_overlay_text}>
															{t("common.alreadyPosses")}{" "}
															{content.type === "image" ? t("common.questa") : t("common.questo")}{" "}
														</Text>
														<Text style={styles.bought_overlay_content_type}>
															{content.type === "image" ? t("common.photo") : t("common.video")}
														</Text>
													</Pressable>
												</>
											) : (
												<>
													<Pressable onPress={goToPurchasePage}>
														<LinearGradient
															colors={["#AB3191", "#DB7BC6", "#A72A8B"]}
															start={{ x: 0, y: 0 }}
															end={{ x: 1, y: 0 }}
															style={{
																width: "100%",
																borderRadius: 16,
																justifyContent: "center",
																flexDirection: "row",
																alignItems: "center",
																gap: 8,
															}}
															// style={{ position: "absolute", bottom: 0, height: 150, right: 0, width: "100%", flex: 1 }}
														>
															<Feather name="unlock" size={24} color="white" />
															<View style={styles.sblock_overlay}>
																<Text style={styles.sblock_overlay_text}>{t("common.unlock")} </Text>
																<Text style={styles.sblock_overlay_content_type}>
																	{content.type === "image" ? t("common.photo") : t("common.video")}
																</Text>
															</View>
														</LinearGradient>
													</Pressable>
												</>
											)}
										</View>
									</View>
								</>
							)}
						</View>
					</>
				)}
			</View>
		</>
	);
}

const styles = StyleSheet.create({
	container: {
		backgroundColor: "#232839",
		flex: 1,
		marginLeft: 10,
		marginRight: 10,
		// marginBottom: 32,
		paddingBottom: 16,
		paddingTop: 8,
		display: "flex",
		borderRadius: 16,
		rowGap: 8,
	},
	image_container: {
		flex: 1,
		width: "100%",
		position: "relative",
		display: "flex",
		alignItems: "center",
	},
	sblock_overlay: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "transparent",
		// position: "absolute",
		// width: "80%",
		paddingVertical: 16,
		// bottom: 36,
		// left: "10%",
		// right: "10%",
		borderRadius: 16,
		zIndex: 100,
	},
	sblock_overlay_text: {
		fontSize: 16,
		color: "white",
		fontWeight: "bold",
	},
	sblock_overlay_content_type: {
		fontSize: 16,
		color: "white",
		fontWeight: "bold",
	},
	bought_overlay: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		alignSelf: "center",
		backgroundColor: "#151721",
		width: "80%",
		padding: 16,

		borderRadius: 16,
		zIndex: 100,
	},
	bought_overlay_text: {
		color: "white",
		fontWeight: "bold",
	},
	bought_overlay_content_type: {
		color: "white",
		fontWeight: "bold",
	},
	image: {
		width: "100%",
		borderRadius: 16,
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0,

		aspectRatio: 3 / 4,
		height: undefined,
	},
	video: {
		width: "100%",
		borderRadius: 16,
		backgroundColor: "transparent",
		aspectRatio: 3 / 4,
		height: undefined,
	},
});
function arePropsEqual(
	prevProps: {
		uri: string;
		isLocked: boolean;
		content: ContentType;
		creator: CreatorType;

		isPurchasing?: boolean;
		navigation: any;
		contentHeight?: number;
		imageHeight?: number;
		setHeight?: (height: number) => void;
		setContentHeight?: (height: number) => void;
		setImageHeight?: (height: number) => void;
		setAspectRatioRef?: (ratio: number) => void;
		ratio?: number;
	},
	nextProps: {
		uri: string;
		isLocked: boolean;
		content: ContentType;
		creator: CreatorType;

		isPurchasing?: boolean;
		navigation: any;
		contentHeight?: number;
		imageHeight?: number;
		setHeight?: (height: number) => void;
		setContentHeight?: (height: number) => void;
		setImageHeight?: (height: number) => void;
		setAspectRatioRef?: (ratio: number) => void;
		ratio?: number;
	}
) {
	return (
		nextProps.content.title === prevProps.content.title &&
		nextProps.uri === prevProps.uri &&
		nextProps.isLocked === prevProps.isLocked &&
		nextProps.content.type === prevProps.content.type &&
		nextProps.creator === prevProps.creator &&
		nextProps.content.matic === prevProps.content.matic &&
		nextProps.navigation === prevProps.navigation &&
		nextProps.contentHeight === prevProps.contentHeight &&
		nextProps.imageHeight === prevProps.imageHeight &&
		nextProps.setHeight === prevProps.setHeight &&
		nextProps.setContentHeight === prevProps.setContentHeight &&
		nextProps.setImageHeight === prevProps.setImageHeight &&
		nextProps.setAspectRatioRef === prevProps.setAspectRatioRef &&
		nextProps.ratio === prevProps.ratio
	);
}
export default memo(Content, arePropsEqual);
// export default Content;
