import { StyleSheet, Image, View } from "react-native";
import logo from "../../assets/logo.png";

export default function Header() {
	return (
		<View style={styles.header_container}>
			<Image source={logo} style={{ width: "50%", maxWidth: 200, aspectRatio: 1398 / 306 }} />
		</View>
	);
}

const styles = StyleSheet.create({
	header_container: {
		paddingLeft: 1,
		paddingRight: 1,
		display: "flex",
		flexDirection: "row",
		gap: 2,
		padding: 8,
		backgroundColor: "#151721",
	},
});
