import { createContext, useContext, useEffect } from "react";
import { ImageContext } from "../types/providers/ImageProvider";
import { CustomImageType } from "../types/Images";
import {
	cacheImage,
	cleanupCameraCache,
	cleanupChace,
	cleanupImagePickerCache,
	createImageFolderExists,
	findImageInCache,
	getImageFolderExists,
} from "../utils/cache/CacheUtils";
import { Platform } from "react-native";
const ImageContextImpl = createContext<ImageContext>({} as ImageContext);
export const ImageProvider = ({ children }: { children: JSX.Element }) => {
	useEffect(() => {
		if (Platform.OS !== "web") {
			handleCretionOfImageFolder();
			cleanupChace();
			cleanupImagePickerCache();
			cleanupCameraCache();
		}
	}, []);

	const handleCretionOfImageFolder = async (): Promise<void> => {
		const folderExists = await getImageFolderExists();
		if (!folderExists) {
			await createImageFolderExists();
		}
	};

	const getImage = async (
		url: string,
		imageName: string
	): Promise<CustomImageType> => {
		if (Platform.OS === "web")
			return { uri: url + imageName, cacheUri: url + imageName };
		const imageUrl = url + imageName;
		const imageInCache = await findImageInCache(imageName);
		if (imageInCache.exists)
			return { uri: imageUrl, cacheUri: imageInCache.path as string };
		const image = await saveImageInCache(url, imageName);
		return image;
	};
	const saveImageInCache = async (
		url: string,
		imageName: string
	): Promise<CustomImageType> => {
		const imageUrl = url + imageName;

		const image = await cacheImage(imageUrl, imageName);
		if (image.err) {
			console.error("Error in saving image in cache");
		}

		return { uri: imageUrl, cacheUri: image.path as string } as CustomImageType;
	};

	const values = {
		getImage,
	};

	return (
		<ImageContextImpl.Provider value={values}>
			{children}
		</ImageContextImpl.Provider>
	);
};

export const useImageContext = () => useContext(ImageContextImpl);
