import { AuthenticationProvider } from "./AuthenticationProvider";
import { BlockchainProvider } from "./BlockchainProvider";
import { ContentProvider } from "./ContentProvider";
import { ImageProvider } from "./ImageProvider";
import { LoadingProvider } from "./LoadingProvider";
import { NetworkProvider } from "./NetworkProvider";
import { SnackbarProvider } from "./SnackBarProvider";
import { TranslationProvider } from "./TranslationProvider";
import { UserProvider } from "./UserProvider";
import { WalletProvider } from "./WalletProvider";

export default function Provider({ children }: { children: JSX.Element }) {
	return (
		<>
			<TranslationProvider>
				<NetworkProvider>
					<LoadingProvider>
						<SnackbarProvider>
							<AuthenticationProvider>
								<WalletProvider>
									<BlockchainProvider>
										<ContentProvider>
											<UserProvider>
												<ImageProvider>{children}</ImageProvider>
											</UserProvider>
										</ContentProvider>
									</BlockchainProvider>
								</WalletProvider>
							</AuthenticationProvider>
						</SnackbarProvider>
					</LoadingProvider>
				</NetworkProvider>
			</TranslationProvider>
		</>
	);
}
