import { createContext, useContext, useState, useEffect } from "react";
import { RedirectionContextType } from "../types/providers/RedirectionProvider";

const RedirectionContextImpl = createContext<RedirectionContextType>({} as RedirectionContextType);
export const RedirectionProvider = ({ children }: { children: JSX.Element }) => {
	const [hasParams, setHasParams] = useState<boolean>(false);
	const values = {
		hasParams,
		setHasParams,
	};

	return <RedirectionContextImpl.Provider value={values}>{children}</RedirectionContextImpl.Provider>;
};

export const useRedirectionContext = () => useContext(RedirectionContextImpl);
