import { Snackbar } from "react-native-paper";
import { useSnackbar } from "../../providers/SnackBarProvider";
import { View, Text, StyleSheet } from "react-native";
export const Snackbars = () => {
	const { snackbarTitle, snackbarMessage, snackbarSeverity, snackbarOpen, closeSnackbar } = useSnackbar();

	return (
		<>
			<Snackbar
				visible={snackbarOpen}
				onDismiss={() => closeSnackbar()}
				duration={5000}
				wrapperStyle={{ bottom: 0, alignItems: "center" }}
				style={{ backgroundColor: "#181818", zIndex: 999, elevation: 999, width: "80%", borderRadius: 8, paddingHorizontal: 8 }}
			>
				<View style={styles.title_container}>
					<Text style={{ color: "#ddd", fontSize: 18, fontFamily: "Gilroy-Bold" }}>{snackbarTitle}</Text>
				</View>
				<View style={styles.message_container}>
					<Text style={{ color: "#ddd", fontSize: 14, fontFamily: "Gilroy-Bold" }}>{snackbarMessage}</Text>
				</View>
			</Snackbar>
		</>
	);
};
const styles = StyleSheet.create({
	title_container: { flex: 1 },
	message_container: { flex: 1, paddingHorizontal: 16 },
});
