import {
	StyleSheet,
	FlatList,
	Modal,
	View,
	Text,
	Pressable,
	TouchableOpacity,
	Platform,
} from "react-native";

import Content from "../components/common/Content";
import LoadingIndicator from "../components/common/LoadingIndicator";

import { useContentContext } from "../providers/ContentProvider";
import { SafeAreaView } from "react-native-safe-area-context";
import Header from "../components/common/Header";
import { useEffect, useRef, useState } from "react";
import { RefreshControl } from "react-native-gesture-handler";
import LoadingComponent from "../components/loading/LoadingComponent";
import { CategoryType, FeedContentType } from "../types/content/Contents";

import Accordion from "../components/common/Accordion";
import CategoryButton from "../components/Categories/CategoryButton";
import * as Linking from "expo-linking";
import { useFocusEffect } from "@react-navigation/native";
import { StatusBar } from "expo-status-bar";
import {
	PanGestureHandler,
	gestureHandlerRootHOC,
} from "react-native-gesture-handler";
import { SvgXml } from "react-native-svg";
import Svg, { Circle, Path, Rect, G } from "react-native-svg";
import { useIsFocused } from "@react-navigation/native";

import { patchFlatListProps } from "react-native-web-refresh-control";
import { useRedirectionContext } from "../providers/RedirectionProvider";
import { useTranslation } from "react-i18next";
if (Platform.OS === "web") patchFlatListProps({ tintColor: "#DB7BC6" });

export default function Feed({ navigation }: { navigation: any }) {
	const {
		feedContent,
		incrementFeedContentDisplayed,
		isLoadingFeedContent,
		refreshContent,
		categories,
		contents,
		getCreator,
		getSavedCategoriesSelected,
		saveCategoriesSelected,
		SB,
		addressEmptyFeed,
	} = useContentContext();

	const [refreshing, setRefresh] = useState<boolean>(false);
	const [categoriesSelected, setCategoriesSelected] = useState<number[]>([]);
	const [maxContent, setMaxContent] = useState<number>(4);
	const [isLoadingFilteredContent, setIsLoadingFilteredContent] =
		useState<boolean>(false);
	const [endReached, setEndReached] = useState<boolean>(false);
	const [promptCategoryInsertionOpened, setPromptCategoryInsertionOpened] =
		useState<boolean>(false);
	const [open, setOpen] = useState(false);

	const [filteredContent, setFilteredContent] = useState<FeedContentType[]>([
		{
			content: {
				type: "categories",
				address: "",
				timestamp: 0,
				category: 0,
				paidContent: "",
				previewImage: "",
				matic: 0,
				title: "",
				id: -1,
			},
			creator: {
				address: "",
				name: "",
				profileImageURL: "",
				bio: "",
				coverImageURL: "",
				instagram: "",
				telegram: "",
				social4: "",
				twitter: "",
			},
		},
	]);
	const { hasParams } = useRedirectionContext();
	const listRef = useRef<FlatList>(null);
	function handleToggleCategory(category: CategoryType) {
		if (categoriesSelected.includes(category.id - 1)) {
			const index = categoriesSelected.indexOf(category.id - 1);
			let newCategoriesSelected = [...categoriesSelected];
			newCategoriesSelected.splice(index, 1);
			setCategoriesSelected([...newCategoriesSelected]);
		} else {
			setCategoriesSelected([...categoriesSelected, category.id - 1]);
		}
		setMaxContent(4);
		setFilteredContent([
			{
				content: {
					type: "categories",
					address: "",
					timestamp: 0,
					category: 0,
					paidContent: "",
					previewImage: "",
					matic: 0,
					title: "",
					id: -1,
				},
				creator: {
					address: "",
					name: "",
					profileImageURL: "",
					bio: "",
					coverImageURL: "",
					instagram: "",
					telegram: "",
					social4: "",
					twitter: "",
				},
			},
		]);
		setEndReached(false);
	}

	async function handleReload() {
		setRefresh(true);
		await refreshContent();
		setRefresh(false);
	}
	function handleLoadMore() {
		if (
			!isLoadingFilteredContent &&
			filteredContent.length > 1 &&
			!endReached
		) {
			setIsLoadingFilteredContent(true);
			setMaxContent(maxContent + 10);
		}
	}

	async function handleContentFilter(emptyFeed: boolean) {
		if (!endReached) {
			const feedContentList: FeedContentType[] = [];
			if (categoriesSelected.length !== 0 && !emptyFeed) {
				const contentsList = contents.filter((item) =>
					categoriesSelected.includes(item.category - 1)
				);
				let lastIndex = -1;
				if (filteredContent.length > 1)
					lastIndex = contentsList.findIndex(
						(item) =>
							item.id == filteredContent[filteredContent.length - 1].content.id
					);
				let skippedContent = 0;
				for (
					let i = lastIndex + 1;
					i < contentsList.length && i < maxContent;
					i++
				) {
					const creator = await getCreator(
						contentsList[i + skippedContent].address
					);
					if (!SB.includes(creator.address)) {
						feedContentList.push({
							content: contentsList[i + skippedContent],
							creator: creator,
						});
					} else {
						skippedContent++;
					}
				}
				const current_feed = [...filteredContent, ...feedContentList];

				if (
					current_feed.length - 1 === contentsList.length &&
					contentsList.length !== 0
				)
					setEndReached(true);
				setIsLoadingFilteredContent(false);

				setFilteredContent(current_feed);
			} else {
				const contentsList = contents.filter((item) =>
					addressEmptyFeed.includes(item.address)
				);

				let lastIndex = -1;
				if (filteredContent.length > 1)
					lastIndex = contentsList.findIndex(
						(item) =>
							item.id == filteredContent[filteredContent.length - 1].content.id
					);

				// this part can be improved
				for (
					let i = lastIndex + 1;
					i < contentsList.length && i < maxContent;
					i++
				) {
					const creator = await getCreator(contentsList[i].address);
					feedContentList.push({ content: contentsList[i], creator: creator });
					const current_feed = [...filteredContent, ...feedContentList];
					if (
						current_feed.length - 1 === contentsList.length &&
						contentsList.length !== 0
					)
						setEndReached(true);
					setIsLoadingFilteredContent(false);
					setFilteredContent(current_feed);
				}
			}
		}
	}

	useEffect(() => {
		(async () => {
			const savedCat = await getSavedCategoriesSelected();
			handleContentFilter(savedCat == null);
		})();
	}, [categoriesSelected, maxContent, contents, SB, addressEmptyFeed]);

	useEffect(() => {
		elContentLenghtRef.current.clear();
		elImageLenghtRef.current.clear();
		elAspectRatioRef.current.clear();
	}, [categoriesSelected]);

	useEffect(() => {
		(async () => {
			const savedCat = await getSavedCategoriesSelected();
			if (savedCat == null && !hasParams) {
				setPromptCategoryInsertionOpened(true);
				setOpen(true);
			} else {
				if (savedCat == null) setCategoriesSelected([]);
				else setCategoriesSelected(savedCat);
				await handleContentFilter(savedCat == null);
			}
		})();
	}, []);
	const isFocused = useIsFocused();
	useEffect(() => {
		if (isFocused) {
			(async () => {
				if (!promptCategoryInsertionOpened && !hasParams) {
					const savedCat = await getSavedCategoriesSelected();
					if (savedCat == null && !hasParams) {
						setOpen(true);
						setPromptCategoryInsertionOpened(true);
					} else {
						if (savedCat == null) setCategoriesSelected([]);
						else setCategoriesSelected(savedCat);
						await handleContentFilter(savedCat == null);
					}
				}
			})();
		}
	}, [isFocused]);

	const handleBack = () => {
		saveCategoriesSelected(categoriesSelected);
		setOpen(false);
	};
	const elContentLenghtRef = useRef<Map<number, number>>(
		new Map<number, number>()
	);
	const elImageLenghtRef = useRef<Map<number, number>>(
		new Map<number, number>()
	);
	const elAspectRatioRef = useRef<Map<number, number>>(
		new Map<number, number>()
	);

	return (
		<>
			<SafeAreaView style={{ backgroundColor: "#151721", flex: 1 }}>
				<FlatList
					ref={listRef}
					ListHeaderComponent={
						<>
							<View style={{ flex: 1, height: "100%", minWidth: "40%" }}>
								<Header />
							</View>
							<TouchableOpacity
								style={styles.accordHeader}
								activeOpacity={0.6}
								onPress={() => {
									setOpen(true);
								}}
							>
								<Svg
									width={24}
									height={24}
									viewBox="0 0 24 24"
									stroke="white"
									fill="none"
								>
									<G id="grid">
										<Path
											d="M10 3H3V10H10V3Z"
											strokeWidth="2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<Path
											d="M21 3H14V10H21V3Z"
											strokeWidth="2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<Path
											d="M21 14H14V21H21V14Z"
											strokeWidth="2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<Path
											d="M10 14H3V21H10V14Z"
											strokeWidth="2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</G>
								</Svg>
							</TouchableOpacity>
						</>
					}
					ListHeaderComponentStyle={{
						paddingHorizontal: 8,
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						backgroundColor: "#151721",
					}}
					stickyHeaderIndices={[0]}
					stickyHeaderHiddenOnScroll={true}
					style={styles.feed}
					contentContainerStyle={styles.feed_inside_container}
					data={filteredContent}
					onRefresh={Platform.OS === "web" ? handleReload : undefined}
					refreshing={Platform.OS === "web" ? refreshing : undefined}
					onEndReached={handleLoadMore}
					ItemSeparatorComponent={() => <View style={{ height: 32 }}></View>}
					refreshControl={
						<RefreshControl
							refreshing={refreshing}
							onRefresh={handleReload}
							tintColor={"#DB7BC6"}
						/>
					}
					renderItem={({ item, index }) => {
						if (item.content.type == "categories") {
							return <View style={{ marginTop: 0 }}></View>;
						}
						return (
							<Content
								uri={item.content.previewImage}
								isLocked={true}
								content={item.content}
								creator={item.creator}
								navigation={navigation}
								setContentHeight={(height) => {
									if (height > 0) {
										elContentLenghtRef.current.set(index, height);
									}
								}}
								setImageHeight={(height) => {
									if (height > 0) {
										elImageLenghtRef.current.set(index, height);
									}
								}}
								setAspectRatioRef={(ratio) => {
									if (ratio > 0) {
										elAspectRatioRef.current.set(index, ratio);
									}
								}}
								ratio={elAspectRatioRef.current.get(index)}
								imageHeight={elImageLenghtRef.current.get(index)}
								contentHeight={elContentLenghtRef.current.get(index)}
							/>
						);
					}}
					initialNumToRender={Platform.OS === "web" ? 4 : 0}
					maxToRenderPerBatch={4}
					updateCellsBatchingPeriod={1}
					windowSize={Platform.OS === "web" ? 8 : 2}
					viewabilityConfig={{ waitForInteraction: false }}
					keyExtractor={(item, index) => index.toString()}
					onEndReachedThreshold={1}
					onMomentumScrollEnd={() => {
						if (
							isLoadingFilteredContent &&
							filteredContent.length > 1 &&
							maxContent < filteredContent.length - 1 &&
							maxContent > 4
						) {
							listRef.current?.scrollToEnd({
								animated: true,
							});
						}
					}}
					ListFooterComponent={
						<>
							{!endReached && (
								<LoadingIndicator isLoading={isLoadingFilteredContent} />
							)}
						</>
					}
				/>
			</SafeAreaView>
			<CategoriesSelectionModal
				open={open}
				handleBack={handleBack}
				categories={categories}
				handleToggleCategory={handleToggleCategory}
				categorySelected={categoriesSelected}
			/>
		</>
	);
}
function CategoriesSelection({
	categories,
	categorySelected,
	handleToggleCategory,
}: {
	categories: CategoryType[];
	categorySelected: number[];
	handleToggleCategory: (category: CategoryType) => void;
}) {
	return (
		<>
			<View style={styles.categories_container}>
				{categories.length != 0 &&
					categories.map((category) => {
						return (
							<CategoryButton
								key={category.name}
								category={category}
								categorySelected={categorySelected.includes(category.id - 1)}
								handleToggleCategory={handleToggleCategory}
							/>
						);
					})}
			</View>
		</>
	);
}

interface CategoriesSelectionProps extends JSX.IntrinsicAttributes {
	categories: CategoryType[];
	categorySelected: number[];
	handleToggleCategory: (category: CategoryType) => void;
	handleBack: () => void;
}
const CategoriesSelectionModalContent: React.ComponentType<{
	categories: CategoryType[];
	categorySelected: number[];
	handleToggleCategory: (category: CategoryType) => void;
	handleBack: () => void;
}> = ({
	handleBack,
	categories,
	categorySelected,
	handleToggleCategory,
}: CategoriesSelectionProps) => {
	const [firstCatSelection, setFirstCatSelection] = useState<boolean>(true);
	const { getSavedCategoriesSelected } = useContentContext();
	const { t } = useTranslation();
	useEffect(() => {
		(async () => {
			const savedCat = await getSavedCategoriesSelected();
			if (savedCat != null) {
				setFirstCatSelection(false);
			}
		})();
	}, []);
	return (
		<>
			<StatusBar backgroundColor="#151721" style="light" />
			<PanGestureHandler
				onActivated={() => {
					handleBack();
				}}
				activeOffsetX={1000000}
				activeOffsetY={100}
			>
				<View style={styles.image_container}>
					{!firstCatSelection ? (
						<Pressable onPress={handleBack} style={styles.back_button}>
							<Text style={{ color: "white" }}>{t("common.back_button")}</Text>
						</Pressable>
					) : (
						<Text
							style={{
								fontFamily: "Gilroy-Bold",
								fontSize: 24,
								color: "white",
								marginLeft: 16,
								marginBottom: 16,
							}}
						>
							{t("categorySelection.select_category")}
						</Text>
					)}
					<CategoriesSelection
						categories={categories}
						handleToggleCategory={handleToggleCategory}
						categorySelected={categorySelected}
					/>
					{firstCatSelection && (
						<>
							<Pressable
								onPress={handleBack}
								style={{
									marginTop: 16,
									backgroundColor: "#DB7BC6",
									padding: 16,
									paddingHorizontal: 32,
									borderRadius: 16,
									alignSelf: "center",
									width: "90%",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Text
									style={{
										fontFamily: "Gilroy-Bold",
										fontSize: 20,
										color: "white",
									}}
								>
									{t("common.confirm")}
								</Text>
							</Pressable>
						</>
					)}
				</View>
			</PanGestureHandler>
		</>
	);
};
const ImageViewerContainer = gestureHandlerRootHOC<CategoriesSelectionProps>(
	CategoriesSelectionModalContent
);

function CategoriesSelectionModal({
	handleBack,
	categories,
	categorySelected,
	handleToggleCategory,
	open,
}: {
	categories: CategoryType[];
	categorySelected: number[];
	handleToggleCategory: (category: CategoryType) => void;
	handleBack: () => void;
	open: boolean;
}) {
	return (
		<>
			<Modal
				animationType="fade"
				transparent={true}
				visible={open}
				statusBarTranslucent={false}
				onRequestClose={() => {
					handleBack();
				}}
			>
				<View style={{ flex: 1, backgroundColor: "#151721" }}>
					<ImageViewerContainer
						categorySelected={categorySelected}
						categories={categories}
						handleToggleCategory={handleToggleCategory}
						handleBack={handleBack}
					/>
				</View>
			</Modal>
		</>
	);
}
const styles = StyleSheet.create({
	feed: {
		backgroundColor: "#151721",
	},
	feed_inside_container: {
		paddingBottom: 100,
	},
	categories_container: {
		marginHorizontal: 16,
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		gap: 16,
		rowGap: 16,
		backgroundColor: "transparent",
		alignItems: "center",
	},
	image_container: {
		width: "100%",
		height: "100%",
		position: "relative",
		backgroundColor: "rgb(0,0,0)",
		paddingTop: 64,
	},

	back_button: {
		position: "absolute",
		top: 15,
		left: 15,
		color: "white",
		marginVertical: 15,
		textAlign: "center",
		fontSize: 17,
		marginLeft: 15,
	},
	accordHeader: {
		// marginRight: 8,
		padding: 8,
		backgroundColor: "transparent",
		color: "#DB7BC6",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		borderRadius: 32,
		// borderWidth: 2,
		// borderColor: "#DB7BC6",
		alignSelf: "center",
	},
});
