import { useEffect, useState } from "react";
import { StyleSheet, View, Pressable, Text, Platform } from "react-native";
import { useWalletContext } from "../../providers/WalletProvider";
import Pin from "../../pages/Pin";
import Checkbox from "expo-checkbox";
import { useAuthenticationContext } from "../../providers/AuthenticationProvider";
import * as Clipboard from "expo-clipboard";
import { useSnackbar } from "../../providers/SnackBarProvider";
import { Feather } from "@expo/vector-icons";
import * as LocalAuthentication from "expo-local-authentication";
import { useTranslation } from "react-i18next";

export default function WalletCreation({ navigation }: { navigation: any }) {
	const { createWallet, walletInfo, isCorrectPin } = useWalletContext();

	const { setHasWallet, setIsAuthenticated } = useAuthenticationContext();

	const [creatingWallet, setCreatingWallet] = useState<boolean>(true);
	const [pin, setPin] = useState<number[]>([]);

	const [showMnemonic, setShowMnemonic] = useState<boolean>(false);
	const [confirmedMnemonic, setConfirmeincorporatedMenmonic] = useState<boolean>(false);

	async function handleRegistration(pin: number[]) {
		try {
			if (pin.length === 5) {
				const useBiometrics = await LocalAuthentication.isEnrolledAsync();

				await createWallet(pin.join(""), useBiometrics);
				setShowMnemonic(true);
			}
		} catch (e) {
			console.log(e);
		}
	}
	useEffect(() => {
		if (!creatingWallet) {
			setCreatingWallet(true);
		}
	}, []);

	useEffect(() => {
		if (confirmedMnemonic) {
			setHasWallet(true);
			setIsAuthenticated(true);
			navigation.navigate("Home");
		}
	}, [confirmedMnemonic]);
	return (
		<>
			{creatingWallet && (
				<>
					{showMnemonic && walletInfo != null ? (
						<ShowWalletMnemonic mnemonic={walletInfo?.mnemonic} setConfirmeincorporatedMenmonic={setConfirmeincorporatedMenmonic} />
					) : (
						<>
							<Pin pin={pin} setPin={setPin} isRegistration={true} resetPin={false} handleRegistration={handleRegistration} />
						</>
					)}
				</>
			)}
		</>
	);
}
function ShowWalletMnemonic({
	mnemonic,
	setConfirmeincorporatedMenmonic,
}: {
	mnemonic: string;
	setConfirmeincorporatedMenmonic: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const { openSnackbar } = useSnackbar();
	const { t } = useTranslation();
	async function copyMnemonic() {
		try {
			await Clipboard.setStringAsync(mnemonic);
			openSnackbar(t("common.success"), t("messanges.saved_in_clipboard"), "success");
		} catch (e) {
			openSnackbar(t("common.error"), t("errors.saved_in_clipboard"), "error");
			console.log(e);
		}
	}
	return (
		<>
			<View style={{ height: "100%", backgroundColor: "#151721", width: "100%", gap: 16, paddingTop: 64, paddingHorizontal: 32 }}>
				<Text style={[styles.mnemonic_text, { marginBottom: 16 }]}>This is your recovery phrase, be sure to save it.</Text>
				<View style={styles.mnemonic_container}>
					<Text style={styles.mnemonic_text}>{mnemonic}</Text>
					<Pressable
						onPress={async () => {
							await copyMnemonic();
						}}
						style={{
							flexDirection: "row",
							gap: 8,
							alignItems: "center",
							borderRadius: 32,
							backgroundColor: "rgba(219, 123, 198, 0.4)",
							paddingHorizontal: 16,
							paddingVertical: 8,
							alignSelf: "flex-start",
						}}
					>
						<Feather name="copy" size={20} color="white" />
						<Text style={[styles.wallet_information_text, { fontSize: 16, fontFamily: "Gilroy-Bold" }]}>Copy</Text>
					</Pressable>
					{/* </View> */}
				</View>
				<Pressable
					onPress={() => {
						setConfirmeincorporatedMenmonic(true);
					}}
					style={styles.button_style}
				>
					<Text style={styles.button_text_style}>{t("authentication.confirm_menmonic")}</Text>
				</Pressable>
			</View>
		</>
	);
}
const styles = StyleSheet.create({
	button_style: {
		backgroundColor: "#FF69B4",
		padding: 8,
		paddingVertical: 16,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 16,
	},
	button_text_style: {
		color: "#fff",
		fontWeight: "bold",
	},

	wallet_information_text: {
		color: "white",
	},
	mnemonic_container: {
		backgroundColor: "#232839",
		padding: 16,
		borderRadius: 16,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		gap: 8,
	},
	mnemonic_text: {
		fontSize: 24,
		color: "white",
		alignSelf: "flex-start",
	},
});
