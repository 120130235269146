import React, { useState, useRef } from "react";
import { Dimensions, StyleSheet, View, Animated, Easing } from "react-native";
import { LinearGradient } from "expo-linear-gradient";

const styles = StyleSheet.create({
	shimmer: {
		overflow: "hidden",
		backgroundColor: "#1a1a1a",
	},
});

const SCREEN_WIDTH = Dimensions.get("screen").width;
const START = -1;
const END = 1;
const DURATION = 2000;
const COLORS = ["#1a1a1a", "#2a2a2a", "#1a1a1a"];
const LOCATIONS = [0.3, 0.5, 0.7];
const ANIMATION = new Animated.Value(START);

const runAnimation = () => {
	ANIMATION.setValue(START);
	Animated.timing(ANIMATION, {
		toValue: END,
		duration: DURATION,
		easing: Easing.linear,
		useNativeDriver: true,
	}).start(runAnimation);
};

const linear = ANIMATION.interpolate({
	inputRange: [START, END],
	outputRange: [-SCREEN_WIDTH, SCREEN_WIDTH],
});

runAnimation();

const ShimmerContent = ({ width, height }: { width: string | number | undefined; height: string | number | undefined }) => {
	const [positionX, setPositionX] = useState<number>(0);

	const viewRef = useRef<View>(null);
	return (
		<View
			style={[styles.shimmer, { width, height }]}
			ref={viewRef}
			onLayout={() => {
				if (viewRef && viewRef.current != null) {
					viewRef.current.measure((_x, _y, _width, _height, pageX, _pageY) => {
						if (!isNaN(pageX)) setPositionX(pageX);
					});
				}
			}}
		>
			{positionX !== null && (
				<Animated.View
					style={{
						flex: 1,
						left: -positionX,
						transform: [{ translateX: linear }],
					}}
				>
					<LinearGradient
						style={{ flex: 1, width: SCREEN_WIDTH }}
						start={{ x: 0, y: 0 }}
						end={{ x: 1, y: 0 }}
						locations={LOCATIONS}
						colors={COLORS}
					/>
				</Animated.View>
			)}
		</View>
	);
};

export default ShimmerContent;
