import { useAuthenticationContext } from "../providers/AuthenticationProvider";
import WalletInitialization from "../components/Profile/WalletInitialization";
import { useEffect, useState } from "react";
import { useWalletContext } from "../providers/WalletProvider";
import Pin from "./Pin";
import { SafeAreaView } from "react-native-safe-area-context";
import { useIsFocused } from "@react-navigation/native";
import { useSnackbar } from "../providers/SnackBarProvider";
import { useTranslation } from "react-i18next";

export default function Auth({ navigation }: { navigation: any }) {
	const [pin, setPin] = useState<number[]>([]);
	const [loggedUsingBiometrics, setLoggedUsingBiometrics] = useState(false);
	const { getWalletInformation, getWalletFromSecureStorage } = useWalletContext();
	const { hasWallet, setIsAuthenticated, isAuthenticated } = useAuthenticationContext();
	const { openSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const isFocused = useIsFocused();

	async function handlePinFilled() {
		const wallet = await getWalletInformation(pin.join(""));
		if (wallet.mnemonic != null) setIsAuthenticated(true);
		else {
			openSnackbar(t("common.error"), t("errors.incorrect_pin"), "error");
		}
	}
	async function handleLoginWithBiometrics() {
		try {
			await getWalletFromSecureStorage();
			setIsAuthenticated(true);
		} catch (err) {
			const error = err as Error;
			let errorMessage = t("errors.something_went_wrong");
			if (error.message === "Unable to get the wallet information") errorMessage = t("errors.unable_to_get_the_wallet_information");
			openSnackbar(t("common.error"), errorMessage, "error");
		}
	}
	useEffect(() => {
		if (isAuthenticated) {
			navigation.navigate("Home");
		}
	}, [isAuthenticated]);
	useEffect(() => {
		if (pin.length === 5) handlePinFilled();
	}, [pin]);

	useEffect(() => {
		if (loggedUsingBiometrics) {
			handleLoginWithBiometrics();
		}
	}, [loggedUsingBiometrics]);
	useEffect(() => {
		setLoggedUsingBiometrics(false);
		setPin([]);
	}, [isFocused]);
	return (
		<>
			{isFocused && (
				<SafeAreaView style={{ backgroundColor: "#151721", flex: 1 }}>
					{hasWallet ? (
						<Pin pin={pin} setPin={setPin} setLoggedUsingBiometrics={setLoggedUsingBiometrics} isRegistration={false} resetPin={false} />
					) : (
						<WalletInitialization navigation={navigation} />
					)}
				</SafeAreaView>
			)}
		</>
	);
}
