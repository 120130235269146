import { Alert, ScrollView, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import QRCode from "react-native-qrcode-svg";

import { useWalletContext } from "../../providers/WalletProvider";
import { useEffect, useState } from "react";
import Pin from "../Pin";
import { WalletInfoType } from "../../types/providers/Wallet";
import WalletInformation from "../../components/Profile/WalletInfomation";
import { useLoadingContext } from "../../providers/LoadingProvider";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "../../providers/SnackBarProvider";
export default function WalletInformationPage() {
	const { isCorrectPin, getWalletFromSecureStorage } = useWalletContext();
	const { setIsLoading } = useLoadingContext();
	const [pin, setPin] = useState<number[]>([]);
	const [loggedUsingBiometrics, setLoggedUsingBiometrics] = useState<boolean>(false);
	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
	const [walletInfo, setWalletInfo] = useState<WalletInfoType>({} as WalletInfoType);
	const [resetPin, setResetPin] = useState<boolean>(false);
	const { t } = useTranslation();
	const { openSnackbar } = useSnackbar();
	useEffect(() => {
		(async () => {
			try {
				if (pin.length === 5) {
					setIsLoading(true);
					const isCorrectPin_ = await isCorrectPin(pin.join(""));
					if (isCorrectPin_) {
						setIsAuthenticated(true);
					} else {
						setPin([]);
						setResetPin(true);
						openSnackbar(t("common.error"), t("errors.incorrect_pin"), "error");
					}
					setIsLoading(false);
				}

				if (loggedUsingBiometrics) {
					setIsLoading(true);
					const walletInfo_ = await getWalletFromSecureStorage();
					setWalletInfo(walletInfo_);
					setIsAuthenticated(true);
					setIsLoading(false);
				}
			} catch (err) {
				const error = err as Error;
				let errorMessage = t("errors.something_went_wrong");
				if (error.message === "Unable to get the wallet information") errorMessage = t("errors.unable_to_get_the_wallet_information");
				openSnackbar(t("common.error"), errorMessage, "error");
			}
		})();
	}, [pin, loggedUsingBiometrics]);

	if (!isAuthenticated) {
		return (
			<>
				<Pin pin={pin} setPin={setPin} isRegistration={false} setLoggedUsingBiometrics={setLoggedUsingBiometrics} resetPin={resetPin} />
			</>
		);
	}

	return (
		<>
			<View style={styles.receive_page_container}>
				<ScrollView style={{ width: "100%" }}>
					<View style={styles.wallet_information}>
						<View style={{ padding: 16, borderRadius: 8, width: "100%" }}>
							<WalletInformation />
						</View>
					</View>
				</ScrollView>
			</View>
		</>
	);
}
const styles = StyleSheet.create({
	receive_page_container: {
		padding: 16,
		flex: 1,
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "#151721",
	},
	wallet_information: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		rowGap: 16,
		justifyContent: "center",
		alignItems: "center",
	},
});
