import QRCode from "react-native-qrcode-svg";

import { View, Text } from "react-native";

export default function DesktopPage() {
	return (
		<>
			<View style={{ flex: 1, width: "100%", height: "100%", backgroundColor: "#151721", justifyContent: "center", alignItems: "center" }}>
				<View
					style={{
						width: "90%",
						backgroundColor: "#232839",
						justifyContent: "center",
						alignItems: "center",
						maxWidth: 600,
						gap: 16,
						padding: 32,
						paddingVertical: 64,
						borderRadius: 32,
					}}
				>
					<Text style={{ fontSize: 24, color: "white", alignSelf: "flex-start" }}>
						<Text>Continua sul tuo </Text>
						<Text style={{ color: "#f48cca" }}>cellulare</Text>
					</Text>
					<Text style={{ fontSize: 18, color: "white", alignSelf: "flex-start" }}>
						<Text>Inquadra il qrcode e goditi la </Text>
						<Text style={{ color: "#f48cca" }}>miglior esperienza </Text>
						<Text>direttamente sul tuo smartphone</Text>
					</Text>
					<View style={{ width: "80%", alignItems: "center", justifyContent: "center" }}>
						<QRCode value="https://app.netlyfans.com" size={300} />
					</View>
				</View>
			</View>
		</>
	);
}
