import { useEffect } from "react";
import { View, ActivityIndicator, StyleSheet, Platform } from "react-native";

export default function LoadingIndicator({ isLoading }: { isLoading: boolean }) {
	if (!isLoading) return null;
	return (
		<>
			<View style={styles.activityIndicator_container}>
				<ActivityIndicator size="large" color="#DB7BC6" />
			</View>
		</>
	);
}
const styles = StyleSheet.create({
	activityIndicator_container: {
		flex: 1,
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
		paddingTop: 50,
		marginBottom: Platform.OS === "web" ? 0 : 100,
	},
});
