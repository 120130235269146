import React, { useEffect, useRef } from "react";
import { View, Modal, StyleSheet, Text, ActivityIndicator, Animated, Pressable, Platform } from "react-native";
import CircularProgress from "react-native-circular-progress-indicator";
import { AntDesign } from "@expo/vector-icons";

export default function UploadLoading({
	isLoading,
	error,
	message,
	progress,
	total,
	handleClose,
}: {
	isLoading: boolean;
	message: string;
	error: boolean;
	total: number;
	progress: number;
	handleClose: () => void;
}) {
	return (
		<Modal animationType="fade" transparent={true} visible={isLoading || error} statusBarTranslucent={true} onRequestClose={handleClose}>
			<View style={styles.centeredView}>
				<View style={styles.modalView}>
					{(error || progress === total) && (
						<Pressable style={{ alignSelf: "flex-end" }} onPress={handleClose}>
							<AntDesign name="close" size={24} color="#DB7BC6" />
						</Pressable>
					)}
					<View style={{ rowGap: 16, justifyContent: "center", alignItems: "center" }}>
						<Text style={styles.modalText}>{message}</Text>

						{Platform.OS !== "web" ? (
							<>
								{progress < total && !error && (
									<CircularProgress
										value={(progress / total) * 100}
										maxValue={100}
										inActiveStrokeColor={"#DB7BC6"}
										activeStrokeColor={"#DB7BC6"}
										inActiveStrokeOpacity={0.2}
									/>
								)}
							</>
						) : (
							<>
								{progress < total && (
									<Text style={{ color: "#DB7BC6", fontSize: 24, padding: 8, fontFamily: "Gilroy-Bold" }}>
										{(total === 0 ? 0 : (progress / total) * 100).toFixed(2)}%
									</Text>
								)}
							</>
						)}
					</View>
				</View>
			</View>
		</Modal>
	);
}

const styles = StyleSheet.create({
	centeredView: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "#000a",
	},
	modalView: {
		display: "flex",
		width: 250,
		padding: 16,
		backgroundColor: "#151721",
		borderRadius: 5,
		justifyContent: "center",
		alignItems: "center",
	},
	image: {
		width: 60,
		height: undefined,
		aspectRatio: 1,
	},

	modalText: {
		color: "white",
		marginVertical: 15,
		textAlign: "center",
		fontSize: 17,
		marginLeft: 15,
	},
});
